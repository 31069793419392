import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
const _hoisted_1 = { class: "mb-4" };
import { VueDraggableNext } from 'vue-draggable-next';
import { computed, inject, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainShowCaseDraggableStats',
    setup(__props) {
        const tableState = inject('tableState');
        const draggableCards = ref([
            { id: 1, name: 'Total Candidates' },
            { id: 2, name: 'Available Candidates' },
            { id: 3, name: 'Average Candidate\'s Age' },
        ]);
        const totalCandidates = computed(() => {
            if (tableState) {
                return tableState.rows.length;
            }
        });
        const averageCandidateAge = computed(() => {
            if (tableState && tableState.rows && tableState.rows.length > 0) {
                const totalAge = tableState.rows.reduce((sum, row) => sum + (row.age || 0), 0);
                const averageAge = totalAge / tableState.rows.length;
                return Math.round(averageAge);
            }
            else {
                return 0; // return 0 or another default value if there are no rows
            }
        });
        const totalAvailableCandidates = computed(() => {
            if (tableState) {
                return tableState.rows.filter(row => {
                    return row.availability === true;
                });
            }
        });
        return (_ctx, _cache) => {
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_card = _resolveComponent("v-card");
            return (_openBlock(), _createBlock(_unref(VueDraggableNext), {
                modelValue: draggableCards.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((draggableCards).value = $event)),
                class: "v-row pb-5"
            }, {
                default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(draggableCards.value, (card, index) => {
                        return (_openBlock(), _createBlock(_component_v_col, {
                            cols: "4",
                            key: card.id
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_card, { class: "rounded-xl text-center d-flex align-center stats-cards" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "2" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_v_icon, { size: "x-large" }, {
                                                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                        _createTextVNode("mdi-drag")
                                                    ])),
                                                    _: 1
                                                })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "8" }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("h3", _hoisted_1, _toDisplayString(card.name), 1),
                                                (card.id === 1)
                                                    ? (_openBlock(), _createBlock(_component_v_card, {
                                                        key: 0,
                                                        class: "pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3",
                                                        variant: "tonal"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(totalCandidates.value), 1)
                                                        ]),
                                                        _: 1
                                                    }))
                                                    : (card.id === 2 && totalAvailableCandidates.value)
                                                        ? (_openBlock(), _createBlock(_component_v_card, {
                                                            key: 1,
                                                            class: "pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3",
                                                            variant: "tonal"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(totalAvailableCandidates.value.length), 1)
                                                            ]),
                                                            _: 1
                                                        }))
                                                        : (card.id === 3)
                                                            ? (_openBlock(), _createBlock(_component_v_card, {
                                                                key: 2,
                                                                class: "pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3",
                                                                variant: "tonal"
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(averageCandidateAge.value), 1)
                                                                ]),
                                                                _: 1
                                                            }))
                                                            : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { cols: "2" })
                                    ]),
                                    _: 2
                                }, 1024)
                            ]),
                            _: 2
                        }, 1024));
                    }), 128))
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
