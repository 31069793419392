import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "text-center" };
import { inject, ref, watch } from 'vue';
// Inject the tableState from a parent or provider
export default /*@__PURE__*/ _defineComponent({
    __name: 'ShowCaseEditEntryForm',
    props: {
        candidateId: {
            type: Number,
            required: true
        },
    },
    setup(__props) {
        const tableState = inject('tableState');
        const validationError = ref(false);
        const form = ref(null);
        const customOccupation = ref('');
        const selectedCandidate = ref(null);
        // Props to receive the candidate id
        const props = __props;
        // Watch the tableState or props.candidateId to update selectedCandidate reactively
        watch(() => props.candidateId, () => {
            if (tableState?.rows && props.candidateId && selectedCandidate) {
                const foundCandidate = tableState.rows.find(candidate => candidate.id === props.candidateId);
                // Assign foundCandidate with default values for missing properties
                selectedCandidate.value = foundCandidate
                    ? {
                        id: foundCandidate.id,
                        name: foundCandidate?.name || '', // Provide a default value
                        email: foundCandidate?.email || '',
                        age: typeof foundCandidate?.age === 'string' ? Number(foundCandidate.age) : foundCandidate?.age || 0,
                        phone: foundCandidate?.phone || '',
                        address: foundCandidate?.address || '',
                        availability: foundCandidate?.availability,
                        occupation: foundCandidate?.occupation || undefined,
                    }
                    : null; // Assign null if no candidate is found
            }
        }, { immediate: true });
        // Function to handle form submission
        const submitForm = () => {
            // Ensure form is validated before submission
            if (form.value?.isValid) {
                // Check if customOccupation has a value
                if (customOccupation.value && selectedCandidate.value) {
                    // Ensure selectedCandidate.value is not null before assigning occupation
                    selectedCandidate.value.occupation = customOccupation.value;
                }
                // Find the index of the candidate to update
                if (tableState?.rows && selectedCandidate.value && props.candidateId) {
                    const candidateIndex = tableState.rows.findIndex(candidate => candidate?.id === props.candidateId);
                    // If the candidate exists, update it
                    if (candidateIndex !== -1) {
                        // Create a copy of the selected candidate, ensuring age is a number
                        const updatedCandidate = {
                            ...selectedCandidate.value,
                            age: typeof selectedCandidate.value.age === 'string' ? Number(selectedCandidate.value.age) : selectedCandidate.value.age,
                            phone: String(selectedCandidate.value.phone),
                        };
                        // Update the specific row in tableState
                        tableState.rows[candidateIndex] = updatedCandidate;
                        // Close the modal (if applicable)
                        closeDialog();
                    }
                }
            }
            else {
                validationError.value = true; // Handle validation error
            }
        };
        const closeDialog = () => {
            const modalCloser = document.querySelector('.modalCloser');
            if (modalCloser instanceof HTMLElement) {
                modalCloser.click();
            }
        };
        return (_ctx, _cache) => {
            const _component_v_alert = _resolveComponent("v-alert");
            const _component_AppInputField = _resolveComponent("AppInputField");
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            const _component_v_form = _resolveComponent("v-form");
            return (selectedCandidate.value && _unref(tableState))
                ? (_openBlock(), _createBlock(_component_v_form, {
                    key: 0,
                    ref_key: "form",
                    ref: form,
                    onSubmit: _withModifiers(submitForm, ["prevent"])
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_alert, {
                            border: "start",
                            color: "indigo-accent-3",
                            icon: "mdi-information-variant-circle",
                            title: "Watchout",
                            ariant: 'outlined',
                            text: "Form validation is implemented here, don't hesitate in typing wrong data. 👀"
                        }),
                        _createVNode(_component_AppInputField, {
                            class: "pt-5",
                            modelValue: selectedCandidate.value.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectedCandidate.value.name) = $event)),
                            inputPlaceholder: 'Candidate\'s name',
                            inputLabel: 'Candidate\'s name...',
                            inputType: 'text',
                            inputId: 'uniq-edit-candidate-name',
                            inputRequired: true
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_AppInputField, {
                            modelValue: selectedCandidate.value.email,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((selectedCandidate.value.email) = $event)),
                            inputPlaceholder: 'Candidate\'s email',
                            inputLabel: 'Candidate\'s email...',
                            inputType: 'email',
                            inputId: 'uniq-edit-candidate-email'
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_AppInputField, {
                            modelValue: selectedCandidate.value.age,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((selectedCandidate.value.age) = $event)),
                            inputPlaceholder: 'Candidate\'s age',
                            inputLabel: 'Candidate\'s age...',
                            inputType: 'text',
                            isNumeric: true,
                            inputId: 'uniq-edit-candidate-age'
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_AppInputField, {
                            modelValue: selectedCandidate.value.phone,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((selectedCandidate.value.phone) = $event)),
                            inputPlaceholder: 'Candidate\'s phone',
                            inputLabel: 'Candidate\'s phone...',
                            inputType: 'text',
                            inputId: 'uniq-edit-candidate-phone'
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_AppInputField, {
                            modelValue: selectedCandidate.value.address,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((selectedCandidate.value.address) = $event)),
                            inputPlaceholder: 'Candidate\'s address',
                            inputLabel: 'Candidate\'s address...',
                            inputType: 'text',
                            inputId: 'uniq-edit-candidate-address'
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_AppInputField, {
                            modelValue: selectedCandidate.value.occupation,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((selectedCandidate.value.occupation) = $event)),
                            inputLabel: 'Candidate\'s occupation',
                            inputPlaceholder: 'Candidate\'s occupation...',
                            inputType: 'select',
                            inputId: 'uniq-edit-candidate-occupation',
                            inputRequired: false,
                            selectItems: _unref(tableState).availableOccupations
                        }, null, 8, ["modelValue", "selectItems"]),
                        (selectedCandidate.value.occupation === 'Other')
                            ? (_openBlock(), _createBlock(_component_AppInputField, {
                                key: 0,
                                modelValue: customOccupation.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((customOccupation).value = $event)),
                                inputPlaceholder: 'Candidate\'s custom occupation...',
                                inputLabel: 'Candidate\'s custom occupation',
                                inputType: 'text',
                                inputRequired: selectedCandidate.value.occupation === 'Other',
                                inputId: 'uniq-edit-candidate-custom-occupation'
                            }, null, 8, ["modelValue", "inputRequired"]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_1, [
                            (validationError.value)
                                ? (_openBlock(), _createBlock(_component_v_alert, {
                                    key: 0,
                                    border: "start",
                                    text: "Please check the highlighted fields and try again.",
                                    title: "Oops! There seems to be an issue",
                                    color: "error",
                                    icon: "mdi-alert-circle",
                                    class: "mb-5"
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                    color: "red",
                                    text: "Cancel",
                                    onClick: _cache[7] || (_cache[7] = ($event) => (closeDialog()))
                                }),
                                _createVNode(_component_v_btn, {
                                    color: "indigo-accent-3",
                                    type: "submit"
                                }, {
                                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode("Done")
                                    ])),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 512))
                : _createCommentVNode("", true);
        };
    }
});
