<template>
	<div class="project-card-wrapper">
		<div class="content">
			<h2>
				<span v-html="$t('projects')"></span>
				<span class="icons Authorization-success"></span>
			</h2>
			<div class="content-toggler-wrapper" :class="showProjects ? 'active' : ''">
				<div class="content-toggler" @click="toggleProjects">
					<h3>
						<span class="icons Dirrection"></span>
						<span class="text" v-html="$t('projects_sites')"></span>
					</h3>
					<div class="icons Arrow-2" :class="showProjects ? 'down' : ''"></div>
					<div class="content-section" v-if="showProjects">
						<a v-for="(project, index, key) in projects" :key="key" target="_blank" :href="project.link"
							class="project-card" :class="project.code" @click.stop>
							<div class="project-content">
								<div class="project-image"></div>
								<h4 class="title"><span v-html="project.title"></span> <span class="icons Surfing"></span></h4>
								<div class="description" v-html="$t(`projects_title_${project.id}`)"></div>
								<div class="status" v-if="project.status" v-html="project.status"></div>
							</div>
						</a>
					</div>
					<div class="content-section" v-if="showProjects">
						<a v-for="(subProject, index, key) in subProjects" :key="key" target="_blank" :href="subProject.link"
							class="project-card" :class="subProject.code" @click.stop>
							<div class="project-content">
								<div class="project-image"></div>
								<h4 class="title"><span v-html="subProject.title"></span> <span class="icons Surfing"></span></h4>
								<div class="description" v-html="$t(`projects_title_${subProject.id}`)"></div>
								<div class="status" v-if="subProject.status" v-html="subProject.status"></div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="content-toggler-wrapper" :class="showDesigns ? 'active' : ''">
				<div class="content-toggler" @click="toggleDesigns">
					<h3>
						<span class="icons Image"></span>
						<span class="text" v-html="$t('projects_illustrations')"></span>
					</h3>
					<div class="icons Arrow-2" :class="showDesigns ? 'down' : ''"></div>
					<div class="content-section" v-if="showDesigns">
						<a v-for="(design, index) in designs" :key="index"
							:class="'project-card ' + design.code + [design.link ? '' : ' disabled']" :href="design.link || null"
							:target="design.link ? '_blank' : null" @click.stop>
							<div class="project-content">
								<div :class="'design-viewer ' + design.code + [design.whiteBg ? ' white-bg' : '']"></div>
								<h4 class="title"><span v-html="design.title"></span> <span v-if="design.link"
										class="icons Surfing"></span>
								</h4>
								<div class="description" v-html="design.id ? $t(`projects_title_${design.id}`) : design.description">
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="content-toggler-wrapper" :class="showSide ? 'active' : ''">
				<div class="content-toggler" @click="toggleSide">
					<h3>
						<span class="icons Like-1"></span>
						<span class="text" v-html="$t('projects_side')"></span>
					</h3>
					<div class="icons Arrow-2" :class="showSide ? 'down' : ''"></div>
					<div class="content-section" v-if="showSide">
						<a v-for="(project, index) in sideProjects" :key="index"
							:class="'project-card ' + project.code + [project.link ? '' : ' disabled']" :href="project.link || null"
							:target="project.link ? '_blank' : null" @click.stop>
							<div class="project-content">
								<div v-if="project.code === 'wm-loader'" class="body-wm-loader">
									<div class="loader-wrapper">
										<div class="lighthouse">
											<div class="wm-loader-cloud">
												<div class="cloud-part"></div>
											</div>
											<div class="light-1"></div>
											<div class="light-2"></div>
											<div class="top"></div>
											<div class="deck"></div>
											<div class="balcony"></div>
											<div class="tower"></div>
										</div>
									</div>
									<div class='wm-loader-text'><span class="toggle-text-2">Wait</span><span
											class="toggle-text-1">Watch</span><span class="hidden-text">Watch</span> for it...</div>
								</div>
								<div v-if="project.code === 'shuffle-standup'" style="text-align: center;">
									<svg style="margin: auto; max-height: 100px;" x="0px" y="0px" class="trophy"
										viewBox="0 0 135.67233 378.07285" version="1.1" id="svg5" xml:space="preserve"
										inkscape:version="1.2 (dc2aeda, 2022-05-15)" sodipodi:docname="mike.svg"
										xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
										xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
										xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
										<sodipodi:namedview id="namedview7" pagecolor="#ffffff" bordercolor="#000000" borderopacity="0.25"
											inkscape:showpageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0"
											inkscape:deskcolor="#d1d1d1" inkscape:document-units="mm" showgrid="false"
											inkscape:zoom="0.4357743" inkscape:cx="469.27963" inkscape:cy="806.61022"
											inkscape:window-width="1792" inkscape:window-height="975" inkscape:window-x="0"
											inkscape:window-y="25" inkscape:window-maximized="1" inkscape:current-layer="layer1">
										</sodipodi:namedview>
										<defs id="defs2"></defs>
										<g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
											transform="translate(253.40528,89.881852)">
											<g id="g3994">
												<path id="path354"
													d="m -117.74533,-23.071977 h -135.62325 a 67.836128,67.836128 0 0 0 -0.0367,1.026294 67.836128,67.836128 0 0 0 67.83617,67.83617 67.836128,67.836128 0 0 0 67.83617,-67.83617 67.836128,67.836128 0 0 0 -0.0124,-1.026294 z"
													style="opacity: 1; fill: rgb(14, 18, 21); fill-opacity: 1; stroke-width: 1.52127; stop-color: rgb(0, 0, 0);">
												</path>
												<path id="path2709"
													d="m -185.58823,-89.881852 a 67.836128,67.836128 0 0 0 -67.4858,61.345609 h 135.00984 a 67.836128,67.836128 0 0 0 -67.52404,-61.345609 z"
													style="opacity: 1; fill: rgb(14, 18, 21); fill-opacity: 1; stroke-width: 1.52127; stop-color: rgb(0, 0, 0);">
												</path>
												<path id="path531"
													d="m -225.81974,31.461646 c 0,0 -6.29187,13.843591 8.8098,99.419774 15.10169,158.56764 15.1014,157.30926 15.1014,157.30926 h 4.99246 22.69423 4.99194 c 0,0 2.4e-4,1.25838 15.10192,-157.30926 15.10168,-85.576183 8.80928,-99.419774 8.80928,-99.419774 z"
													sodipodi:nodetypes="ccccccccc"
													style="font-variation-settings: normal; opacity: 1; fill: rgb(41, 58, 65); fill-opacity: 1; stroke-width: 1.05833; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-dashoffset: 0; stroke-opacity: 1; stop-color: rgb(0, 0, 0); stop-opacity: 1;">
												</path>
												<path id="path1712"
													d="m -243.76556,12.750126 a 67.836128,67.836128 0 0 0 2.14767,3.417879 67.836128,67.836128 0 0 0 3.01274,4.081405 67.836128,67.836128 0 0 0 3.30884,3.845243 67.836128,67.836128 0 0 0 3.58738,3.587378 67.836128,67.836128 0 0 0 3.84473,3.308842 67.836128,67.836128 0 0 0 3.25613,2.402954 h 78.07792 a 67.836128,67.836128 0 0 0 3.25613,-2.402954 67.836128,67.836128 0 0 0 3.84524,-3.308842 67.836128,67.836128 0 0 0 3.58738,-3.587378 67.836128,67.836128 0 0 0 3.30884,-3.845243 67.836128,67.836128 0 0 0 3.01274,-4.081405 67.836128,67.836128 0 0 0 2.14716,-3.417879 z"
													style="font-variation-settings: normal; opacity: 1; fill: rgb(169, 180, 184); fill-opacity: 1; stroke-width: 1.03282; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-dashoffset: 0; stroke-opacity: 1; stop-color: rgb(0, 0, 0); stop-opacity: 1;">
												</path>
												<circle id="path3180" cx="-185.56911" cy="66.787239" r="4.8572545"
													style="font-variation-settings: normal; opacity: 1; fill: rgb(186, 8, 8); fill-opacity: 1; stroke-width: 32.8337; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-dashoffset: 0; stroke-opacity: 1; stop-color: rgb(0, 0, 0); stop-opacity: 1;">
												</circle>
												<rect id="rect3441" width="12.750291" height="43.108131" x="-191.94426" y="92.894997"
													style="font-variation-settings: normal; opacity: 1; fill: rgb(38, 43, 45); fill-opacity: 1; stroke-width: 25.4329; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-dashoffset: 0; stroke-opacity: 1; stop-color: rgb(0, 0, 0); stop-opacity: 1;">
												</rect>
												<rect id="rect3443" width="12.750291" height="16.393232" x="-191.94426" y="92.894997"
													style="font-variation-settings: normal; opacity: 1; fill: rgb(0, 0, 0); fill-opacity: 1; stroke-width: 15.6837; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-dashoffset: 0; stroke-opacity: 1; stop-color: rgb(0, 0, 0); stop-opacity: 1;">
												</rect>
												<path id="path2711"
													d="m -118.05722,-28.536243 h -135.00984 a 67.836128,67.836128 0 0 0 -0.31368,5.464266 h 135.62325 a 67.836128,67.836128 0 0 0 -0.29973,-5.464266 z"
													style="opacity: 1; fill: rgb(131, 191, 228); fill-opacity: 1; stroke-width: 1.52127; stop-color: rgb(0, 0, 0);">
												</path>
											</g>
										</g>
									</svg>
								</div>
								<div class="vuejs-logo" v-if="project.code === 'showcase'"></div>
								<h4 class="title"><span v-html="project.title"></span> <span v-if="project.link"
										class="icons Surfing"></span>
								</h4>
								<div class="description" v-html="project.id ? $t(`projects_title_${project.id}`) : project.description">
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="btn-send-me-wrapper">
				<router-link :to="{ path: '/me', hash: '#content' }">
					<div class="btn-send-me next inline">
						<span class="text" v-html="$t('next')"></span>
						<span class="icons Arrow"></span>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showProjects: false,
			showDesigns: false,
			showTest: false,
			showSide: false,
		}
	},
	methods: {
		toggleProjects() {
			this.showProjects = !this.showProjects;
		},
		toggleDesigns() {
			this.showDesigns = !this.showDesigns;
		},
		toggleTest() {
			this.showTest = !this.showTest;
		},
		toggleSide() {
			this.showSide = !this.showSide;
		}
	},
	computed: {
		language() {
			return this.$lang;
		},
		projects() {
			return [{
				id: '8',
				title: 'Syncro',
				link: 'https://syncromsp.com/',
				status: '',
				code: 'syncro',
				img: 'syncro-shape.png',
			},
			{
				id: '7',
				title: 'Watchman Monitoring',
				link: 'https://www.watchmanmonitoring.com/',
				status: '',
				code: 'watchman',
				img: 'watchman-shape.png',
			},
			{
				id: '3',
				title: 'Numesia',
				link: 'http://numesia.fr',
				code: 'numesia',
			},
			]
		},
		subProjects() {
			return [{
				id: '2',
				title: 'e-services Mulhouse',
				link: 'https://e-services.mulhouse-alsace.fr/',
				status: '',
				code: 'mulhouse',
				img: 'gen-home-signature.png',
			},
			{
				id: '1',
				title: 'e-services Colmar',
				link: 'https://eservices.portail.colmar.fr/',
				status: '',
				code: 'colmar',
				img: 'logo_colmaretmoi.png',
			},
			{
				id: '5',
				title: 'Rapo Montfermeil',
				link: 'https://montfermeil.rapo-fps.fr/rapo-fps',
				status: '',
				code: 'cloud montfermeil',
				img: 'gen-home-signature.png',
			},
			{
				id: '6',
				title: 'Rapo Orsay',
				link: 'https://orsay.rapo-fps.fr/rapo-fps',
				status: '',
				code: 'cloud orsay',
				img: 'gen-home-signature.png',
			},
			]
		},
		designs() {
			return [{
				id: '9',
				title: 'De Padres a Hijos',
				code: 'padres',
				link: "https://www.youtube.com/channel/UCl_X6QI3mnJsttsp96OsCZQ",
			},
			{
				id: '10',
				title: 'Transport Tourall',
				code: 'tourall',
				whiteBg: true,
				link: 'https://www.facebook.com/transporttourall/',
			},
			{
				id: '11',
				title: 'La Cigogne Enragée',
				code: 'cigogne',
				link: "https://www.facebook.com/La-Cigogne-Enrag%C3%A9e-366860950096067/",
			},
			{
				title: 'Late',
				code: 'late',
				description: 'Logo',
			},
			{
				title: 'Dozens',
				code: 'dozens',
				description: 'CD Cover',
			},
			{
				title: 'Zadig le chien',
				code: 'zlc',
				whiteBg: true,
				description: 'T-shirt',
			},
			{
				title: 'Gator',
				code: 'gator',
				description: 'Fake Logo',
			},
			{
				title: 'Kama CCD',
				code: 'kama',
				whiteBg: true,
				description: 'Logo',
			},
			{
				title: 'Joker',
				code: 'joker',
				description: 'T-shirt',
			},
			{
				title: 'Dotsay',
				code: 'dotsay',
				description: 'Design',
			},
			{
				title: 'The Empty Cage',
				code: 'cage',
				whiteBg: true,
				description: 'T-shirt',
			},
			{
				title: 'Marble',
				code: 'marble',
				description: 'Design',
			},
			]
		},
		sideProjects() {
			return [{
				id: '12',
				title: 'ShuffleStandup',
				code: 'shuffle-standup',
				link: '/standup',
			},
			{
				id: '13',
				title: 'Watchman Loader',
				code: 'wm-loader',
				description: '',
			},
			{
				title: 'Showcase',
				code: 'showcase',
				link: '/showcase',
				description: 'Vue3 small demo',
			},
			]
		}
	},
}
</script>

<style scoped>
.body-wm-loader {
	background-color: #092E3B;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 25px;
	border-radius: 25px;
}

.wm-loader-text {
	font-family: 'Quicksand', sans-serif;
	margin: 15px;
	text-transform: uppercase;
	color: #E8F0F2;
	font-weight: 700;
	animation: text-animation 2s infinite;
	animation-delay: 2s;
	position: relative;
}

.wm-loader-text .hidden-text {
	opacity: 0;
}

.wm-loader-text .toggle-text-1 {
	position: absolute;
	animation: toggle-animation-1 2s infinite;
	animation-delay: 1s;
	top: -50%;
	opacity: 0;
}

.wm-loader-text .toggle-text-2 {
	position: absolute;
	animation: toggle-animation-2 2s infinite;
	animation-delay: 1s;
	top: 0%;
	letter-spacing: 4px;
}

.loader-wrapper {
	width: 150px;
	height: 150px;
	border-radius: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-evenly;
	overflow: hidden;
	border: 5px solid #E8F0F2;
	background-color: #0d495f;
	perspective: 100px;
}

.loader-wrapper .lighthouse {
	position: relative;
	width: 50px;
	text-align: center;
}

.loader-wrapper .lighthouse .top {
	border-bottom: 25px solid #136782;
	border-right: 25px solid transparent;
	border-left: 25px solid transparent;
	border-radius: 15px;
	position: relative;
	top: 17px;
}

.wm-loader-cloud {
	width: 50px;
	height: 50px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	animation: infinite-move 10s infinite;
	animation-delay: 5s;
	opacity: 0.8;
	left: -100px;
	top: 25%;
}

.cloud-part {
	position: absolute;
	border-radius: 100px;
	width: 100%;
	height: 35%;
	background: #E8F0F2;
}

.cloud-part:after,
.cloud-part:before {
	content: '';
	position: absolute;
	background: #E8F0F2;
}

.cloud-part:before {
	border-radius: 50%;
	width: 50%;
	height: 140%;
	right: 10%;
	top: -70%;
}

.cloud-part:after {
	border-radius: 100px;
	height: 140%;
	left: 15%;
	top: -42%;
	width: 50%;
}

.loader-wrapper .lighthouse .deck {
	background-color: #E8F0F2;
	width: 40px;
	height: 15px;
	margin: auto;
	border-radius: 2px;
	position: relative;
	top: 17px;
}

.loader-wrapper .lighthouse .balcony {
	position: relative;
	top: 8px;
	background-color: #136782;
	width: 90%;
	height: 20px;
	transform-style: preserve-3d;
	transform: rotateX(-59deg);
	margin: auto;
	border-radius: 5px;
}

.loader-wrapper .lighthouse .tower {
	position: relative;
	width: 100%;
	height: 80px;
	border-radius: 5px;
	transform-style: preserve-3d;
	transform: rotateX(15deg);
	perspective: 1000px;
	perspective-origin: 50% 50%;
}

.loader-wrapper .lighthouse .tower:after {
	content: "";
	display: block;
	position: absolute;
	top: 45%;
	left: 50%;
	width: 100%;
	height: 500px;
	background-color: #136782;
	z-index: 2;
	transform: translate(-50%, -50%) scaleX(1) scaleY(1) scaleZ(1) rotateX(65deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)
}

.loader-wrapper .lighthouse::before {
	content: "";
	display: block;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 48px;
	left: 50%;
	width: 75px;
	height: 75px;
	background-color: #E8F0F2;
	z-index: 2;
	animation: flash 2s infinite;
	border-radius: 100%;
}

.loader-wrapper .lighthouse::after {
	content: "";
	display: block;
	position: absolute;
	transform: translate(-50%, -50%);
	background-color: transparent;
	top: 48px;
	left: 50%;
	width: 95px;
	height: 95px;
	border: 5px solid #E8F0F2;
	z-index: 2;
	animation: flash-2 2s infinite;
	border-radius: 100%;
	opacity: 0.5;
}

.loader-wrapper .light-1 {
	z-index: -1;
	position: absolute;
	transform: translate(-50%, -50%);
	left: -50%;
	top: 48px;
	width: 0px;
	height: 0px;
	border-top: 0px solid transparent;
	border-bottom: 0px solid transparent;
	border-left: 0px solid #E8F0F2;
	animation: light-1 2s infinite;
	border-top-left-radius: 25%;
	border-bottom-left-radius: 25%;
}

.loader-wrapper .light-2 {
	z-index: -1;
	position: absolute;
	transform: translate(-50%, -50%) rotate(180deg);
	left: 150%;
	top: 48px;
	width: 0px;
	height: 0px;
	border-top: 25px solid transparent;
	border-bottom: 25px solid transparent;
	border-left: 100px solid #E8F0F2;
	animation: light-2 2s infinite;
	animation-direction: reverse;
}

@keyframes light-1 {
	0% {
		border-top: 35px solid transparent;
		border-bottom: 35px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: -50%;
		opacity: 1;
	}

	1% {
		border-top: 35px solid transparent;
		border-bottom: 35px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: 25%;
		opacity: 1;
	}

	50% {
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		border-left: 100px solid #E8F0F2;
		left: -50%;
		opacity: 1;
	}

	75% {
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 100px solid #E8F0F2;
		opacity: 0.7;
		left: -50%;
	}

	100% {
		border-top: 0px solid transparent;
		border-bottom: 0px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: 25%;
		opacity: 1;
	}
}

@keyframes light-2 {
	0% {
		border-top: 0px solid transparent;
		border-bottom: 0px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: 150%;
		opacity: 1;
		border-top-left-radius: 25%;
		border-bottom-left-radius: 25%;
	}

	1% {
		border-top: 35px solid transparent;
		border-bottom: 35px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: 75%;
		opacity: 1;
		border-top-left-radius: 25%;
		border-bottom-left-radius: 25%;
	}

	50% {
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		border-left: 100px solid #E8F0F2;
		left: 150%;
		opacity: 1;
		border-top-left-radius: 25%;
		border-bottom-left-radius: 25%;
	}

	75% {
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 100px solid #E8F0F2;
		opacity: 0.7;
		left: 175%;
		border-top-left-radius: 25%;
		border-bottom-left-radius: 25%;
	}

	100% {
		border-top: 0px solid transparent;
		border-bottom: 0px solid transparent;
		border-left: 0px solid #E8F0F2;
		left: 75%;
		opacity: 1;
		border-top-left-radius: 25%;
		border-bottom-left-radius: 25%;
	}
}

@keyframes flash {
	5% {
		width: 80px;
		height: 80px;
		background-color: #E8F0F2;
		opacity: 1;
		left: 40%;
	}

	25% {
		width: 20px;
		height: 20px;
		background-color: #E8F0F2;
		opacity: 0.2;
		left: 45%;
	}

	50% {
		width: 20px;
		height: 20px;
		background-color: #E8F0F2;
		opacity: 0;
		left: 50%;
	}

	75% {
		width: 20px;
		height: 20px;
		background-color: #E8F0F2;
		opacity: 0.2;
		left: 55%;
	}

	95% {
		width: 80px;
		height: 80px;
		background-color: #E8F0F2;
		opacity: 1;
		left: 60%;
	}
}

@keyframes flash-2 {
	5% {
		width: 80px;
		height: 80px;
		border: 2px solid #E8F0F2;
		left: 40%;
		opacity: 0.5;
	}

	25% {
		width: 10px;
		height: 10px;
		border: 2px solid#E8F0F2;
		left: 45%;
		opacity: 0.25;
	}

	50% {
		width: 5px;
		height: 5px;
		border: 2px solid#E8F0F2;
		opacity: 0;
		left: 50%;
		opacity: 0;
	}

	75% {
		width: 10px;
		height: 10px;
		border: 2px solid #E8F0F2;
		left: 55%;
		opacity: 0.25;
	}

	95% {
		width: 80px;
		height: 80px;
		border: 2px solid #E8F0F2;
		left: 60%;
		opacity: 0.5;
	}
}

@keyframes text-animation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes infinite-move {
	0% {
		left: -100px;
	}

	100% {
		left: 200px;
	}
}

@keyframes toggle-animation-1 {
	0% {
		top: -50%;
		opacity: 0;
	}

	50% {
		top: 0%;
		opacity: 1;
	}

	100% {
		top: 0%;
		opacity: 1;
	}
}

@keyframes toggle-animation-2 {
	0% {
		top: 0%;
		opacity: 1;
		text-decoration: line-through;
	}

	50% {
		top: 100%;
		opacity: 0;
		text-decoration: line-through;
	}

	100% {
		top: 100%;
		opacity: 0;
		text-decoration: line-through;
	}
}
</style>