<template>
	<div>
		<div class="container text-center">

			<div class="bg-breather-container">
				<div class="bg-breather-block bg-breather-block-1"></div>
				<div class="bg-breather-block bg-breather-block-2"></div>
				<div class="bg-breather-block bg-breather-block-3"></div>
				<div class="bg-breather-block bg-breather-block-4"></div>
				<div class="bg-breather-block bg-breather-block-5"></div>
				<div class="bg-breather-block bg-breather-block-6"></div>
				<div class="bg-breather-block bg-breather-block-7"></div>
				<div class="bg-breather-block bg-breather-block-8"></div>
				<div class="bg-breather-block bg-breather-block-9"></div>
				<div class="bg-breather-block bg-breather-block-10"></div>
			</div>

			<h1 class="signature-container">
				<div>
					<div class="AL-font A"></div>
					<span class="AL-font ntony">NTONY</span>
				</div>
				<div>
					<div class="AL-font L"></div>
					<span class="AL-font ourenco">OURENCO</span>
				</div>
			</h1>
			<h2 class="web-int">
				<span v-html="$t('signature_integration')" :data-text="$t('signature_integration')"></span>
				<span class="and" v-html="$t('signature_and')" :data-text="$t('signature_and')"></span>
				<span v-html="$t('signature_webdesign')" :data-text="$t('signature_webdesign')"></span>
			</h2>
		</div>
	</div>
</template>

<script>
	import {
		inject,
		ref
	} from 'vue'
	export default {
		setup() {
			const {
				alTheme
			} = inject('alTheme', {
				alTheme: ref('dark')
			});
			return {
				alTheme
			};
		},
		computed: {
			colorOne() {
				if (this.alTheme === "dark") {
					return "rgba(0,0,0);";
				} else {
					return "rgba(255,255,255);";
				}
			},
		},
	}
</script>
