<template>
  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        text="Cancel"
        @click="closeDialog()">
      </v-btn>
      <v-btn @click="$emit('confirmDeletion')" color="indigo-accent-3" type="submit">Yes, Delete.</v-btn>
    </v-card-actions>
  </div>
</template>

<script setup>
  const closeDialog = () => {
    document.querySelector('.modalCloser')?.click();
  };
</script>
