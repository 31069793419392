<template>
  <v-form ref="form" v-if="selectedCandidate" @submit.prevent="submitForm">
    <v-alert
      border="start"
      color="indigo-accent-3"
      icon="mdi-information-variant-circle"
      title="Watchout"
      :ariant="'outlined'"
      text="Form validation is implemented here, don't hesitate in typing wrong data. 👀">
    </v-alert>
    <AppInputField
      class="pt-5"
      v-model:modelValue="selectedCandidate.name"
      :inputPlaceholder="'Candidate\'s name'"
      :inputLabel="'Candidate\'s name...'"
      :inputType="'text'"
      :inputId="'uniq-edit-candidate-name'"
      :inputRequired="true"
    />
    <AppInputField
      v-model:modelValue="selectedCandidate.email"
      :inputPlaceholder="'Candidate\'s email'"
      :inputLabel="'Candidate\'s email...'"
      :inputType="'email'"
      :inputId="'uniq-edit-candidate-email'"
    />
    <AppInputField
      v-model:modelValue="selectedCandidate.age"
      :inputPlaceholder="'Candidate\'s age'"
      :inputLabel="'Candidate\'s age...'"
      :inputType="'number'"
      :inputId="'uniq-edit-candidate-age'"
    />
    <AppInputField
      v-model:modelValue="selectedCandidate.phone"
      :inputPlaceholder="'Candidate\'s phone'"
      :inputLabel="'Candidate\'s phone...'"
      :inputType="'text'"
      :inputId="'uniq-edit-candidate-phone'"
    />
    <AppInputField
      v-model:modelValue="selectedCandidate.address"
      :inputPlaceholder="'Candidate\'s address'"
      :inputLabel="'Candidate\'s address...'"
      :inputType="'text'"
      :inputId="'uniq-edit-candidate-address'"
    />
    <AppInputField
      v-model:modelValue="selectedCandidate.occupation"
      :inputLabel="'Candidate\'s occupation'"
      :inputPlaceholder="'Candidate\'s occupation...'"
      :inputType="'select'"
      :inputId="'uniq-edit-candidate-occupation'"
      :inputRequired="false"
      :selectItems="tableState.availableOccupations"
    />
    <AppInputField v-if="selectedCandidate.occupation === 'Other'"
      v-model:modelValue="customOccupation"
      :inputPlaceholder="'Candidate\'s custom occupation...'"
      :inputLabel="'Candidate\'s custom occupation'"
      :inputType="'text'"
      :inputRequired="selectedCandidate.occupation === 'Other'"
      :inputId="'uniq-edit-candidate-custom-occupation'"
    />

    <div class="text-center">
      <v-alert v-if="validationError"
        border="start"
        text="Please check the highlighted fields and try again."
        title="Oops! There seems to be an issue"
        color="error"
        icon="mdi-alert-circle"
        class="mb-5">
      </v-alert>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        text="Cancel"
        @click="closeDialog()">
      </v-btn>
      <v-btn color="indigo-accent-3" type="submit">Done</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup>
import { inject, ref, watch } from 'vue';
import AppInputField from '@assets/js/components/FormInputs/AppInputField.vue';

// Inject the tableState from a parent or provider
const tableState = inject('tableState');
const validationError = ref(false);
const form = ref(null);
const customOccupation = ref('');
const selectedCandidate = ref({});

// Props to receive the candidate id
const props = defineProps({
  candidateId: {
    type: Number,
    required: true
  },
});
// Watch the tableState or props.candidateId to update selectedCandidate reactively
watch(
  () => props.candidateId,
  () => {
    if (tableState && props.candidateId) {
      selectedCandidate.value = { ...tableState.rows.find(candidate => candidate.id === props.candidateId) } || {};
    }
  },
  { immediate: true }
);

// Function to handle form submission
const submitForm = () => {
  // Ensure form is validated before submission
  if (form.value && form.value.isValid) {
    // Check if customOccupation has a value and update selectedCandidate.occupation if needed
    if (customOccupation.value) {
      selectedCandidate.value.occupation = customOccupation.value;
    }

    // Find the index of the candidate to update
    if (tableState && selectedCandidate.value && props.candidateId) {
      const candidateIndex = tableState.rows.findIndex(candidate => candidate.id === props.candidateId);

      // If the candidate exists, update it
      if (candidateIndex !== -1) {
        const updatedCandidate = { ...selectedCandidate.value }; // Create a copy of the current candidate

        // Update the specific row in tableState
        tableState.rows[candidateIndex] = updatedCandidate;

        // Close the modal (if applicable)
        closeDialog();
      }
    }
  } else {
    validationError.value = true; // Handle validation error
  }
};

const closeDialog = () => {
  document.querySelector('.modalCloser')?.click();
};
</script>
