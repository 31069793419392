import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
import AppInputField from '@assets/js/showcase/components/form-inputs/AppInputField.vue';
import { reactive, ref, inject } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ShowCaseNewEntryForm',
    setup(__props) {
        const tableState = inject('tableState');
        const uniqId = Date.now(); // Generate unique ID based on the current timestamp
        const newEntry = reactive({
            id: uniqId,
            name: "",
            email: "",
            age: 0,
            phone: "",
            address: "",
            occupation: "",
            availability: false,
        });
        const validationError = ref(false);
        const customOccupation = ref('');
        // Create a ref for the form
        const form = ref(null);
        const submitForm = () => {
            if (form.value && form.value.isValid) {
                // Ensure age is a number (if it was somehow changed to a string)
                newEntry.age = typeof newEntry.age === "string" ? Number(newEntry.age) : newEntry.age;
                if (customOccupation.value) {
                    newEntry.occupation = customOccupation.value;
                }
                if (tableState) {
                    tableState.rows.push(newEntry); // Push new entry to the array
                }
                closeDialog();
            }
            else {
                validationError.value = true; // Handle validation error
            }
        };
        const closeDialog = () => {
            const modalCloser = document.querySelector('.modalCloser');
            if (modalCloser instanceof HTMLElement) {
                modalCloser.click();
            }
        };
        return (_ctx, _cache) => {
            const _component_v_alert = _resolveComponent("v-alert");
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            const _component_v_form = _resolveComponent("v-form");
            return (_unref(tableState))
                ? (_openBlock(), _createBlock(_component_v_form, {
                    key: 0,
                    ref_key: "form",
                    ref: form,
                    onSubmit: _withModifiers(submitForm, ["prevent"])
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_alert, {
                            border: "start",
                            color: "indigo-accent-3",
                            icon: "mdi-information-variant-circle",
                            title: "Watchout!",
                            ariant: 'outlined',
                            text: "From Validation is implemented here, don't hesitate in typing wrong data. 👀"
                        }),
                        _createVNode(AppInputField, {
                            class: "pt-5",
                            modelValue: newEntry.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((newEntry.name) = $event)),
                            inputLabel: 'Candidate\'s name...',
                            inputPlaceholder: 'Candidate\'s name...',
                            inputType: 'text',
                            inputId: 'uniq-new-candidate-name',
                            inputRequired: true
                        }, null, 8, ["modelValue"]),
                        _createVNode(AppInputField, {
                            modelValue: newEntry.email,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((newEntry.email) = $event)),
                            inputLabel: 'Candidate\'s email...',
                            inputPlaceholder: 'Candidate\'s email...',
                            inputType: 'email',
                            inputId: 'uniq-new-candidate-email',
                            inputRequired: false
                        }, null, 8, ["modelValue"]),
                        _createVNode(AppInputField, {
                            modelValue: newEntry.age,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((newEntry.age) = $event)),
                            inputLabel: 'Candidate\'s age...',
                            inputPlaceholder: 'Candidate\'s age...',
                            inputType: 'text',
                            isNumeric: true,
                            inputId: 'uniq-new-candidate-age',
                            inputRequired: false
                        }, null, 8, ["modelValue"]),
                        _createVNode(AppInputField, {
                            modelValue: newEntry.phone,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((newEntry.phone) = $event)),
                            inputLabel: 'Candidate\'s phone...',
                            inputPlaceholder: 'Candidate\'s phone...',
                            inputType: 'text',
                            inputId: 'uniq-new-candidate-phone',
                            inputRequired: false
                        }, null, 8, ["modelValue"]),
                        _createVNode(AppInputField, {
                            modelValue: newEntry.address,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((newEntry.address) = $event)),
                            inputLabel: 'Candidate\'s address...',
                            inputPlaceholder: 'Candidate\'s address...',
                            inputType: 'text',
                            inputId: 'uniq-new-candidate-address',
                            inputRequired: false
                        }, null, 8, ["modelValue"]),
                        _createVNode(AppInputField, {
                            modelValue: newEntry.occupation,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((newEntry.occupation) = $event)),
                            inputLabel: 'Candidate\'s occupation...',
                            inputPlaceholder: 'Candidate\'s occupation...',
                            inputType: 'select',
                            inputId: 'uniq-new-candidate-occupation',
                            inputRequired: false,
                            selectItems: _unref(tableState).availableOccupations
                        }, null, 8, ["modelValue", "selectItems"]),
                        (newEntry.occupation === 'Other')
                            ? (_openBlock(), _createBlock(AppInputField, {
                                key: 0,
                                modelValue: customOccupation.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((customOccupation).value = $event)),
                                inputPlaceholder: 'Candidate\'s custom occupation...',
                                inputLabel: 'Candidate\'s custom occupation',
                                inputType: 'text',
                                inputRequired: newEntry.occupation === 'Other',
                                inputId: 'uniq-new-candidate-custom-occupation'
                            }, null, 8, ["modelValue", "inputRequired"]))
                            : _createCommentVNode("", true),
                        (validationError.value)
                            ? (_openBlock(), _createBlock(_component_v_alert, {
                                key: 1,
                                border: "start",
                                text: "Please check the highlighted fields and try again.",
                                title: "Oops! There seems to be an issue",
                                color: "error",
                                icon: "mdi-alert-circle",
                                class: "mb-5"
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                    color: "red",
                                    text: "Cancel",
                                    onClick: _cache[7] || (_cache[7] = ($event) => (closeDialog()))
                                }),
                                _createVNode(_component_v_btn, {
                                    color: "indigo-accent-3",
                                    type: "submit"
                                }, {
                                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode("Done")
                                    ])),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 512))
                : _createCommentVNode("", true);
        };
    }
});
