import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "d-flex space-between" };
import AppInputField from '@assets/js/showcase/components/form-inputs/AppInputField.vue';
import AppModalDialog from '@assets/js/showcase/components/ui-elements/AppModalDialog.vue';
import ShowCaseNewEntryForm from '@assets/js/showcase/ShowCaseNewEntryForm.vue';
import ShowCaseEditEntryForm from '@assets/js/showcase/ShowCaseEditEntryForm.vue';
import ShowCaseConfirmDeletion from '@assets/js/showcase/ShowCaseConfirmDeletion.vue';
import { computed, inject, ref, toRaw } from 'vue';
// Ensure tableState has default values for filters
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainShowCase',
    setup(__props, { expose: __expose }) {
        const showSnackBarRemoval = ref(false);
        const tableLayout = ref(false);
        const tableState = inject('tableState');
        // Define the type for rows
        if (tableState) {
            tableState.filterAvailability = tableState.filterAvailability || null;
        }
        // Create a computed property for filtered rows
        const filteredRows = computed(() => {
            if (tableState && tableState.rows) {
                // Apply filters to the rows array
                return tableState.rows.filter(row => {
                    // If filterAvailability is true, only show rows with availability true
                    if (tableState.filterAvailability === true) {
                        return row.availability === true;
                    }
                    if (tableState.filterOccupation) {
                        return row.occupation === tableState.filterOccupation;
                    }
                    // If filterAvailability is false, return all rows
                    return true;
                });
            }
        });
        const disableClearFilter = computed(() => {
            if (tableState) {
                return !(tableState.search || tableState.filterAvailability || tableState.filterOccupation);
            }
        });
        const goToLinkedIn = () => {
            window.open("https://www.linkedin.com/in/antonylourenco/", "_blank");
        };
        const clearFilters = () => {
            if (tableState) {
                tableState.filterAvailability = null;
                tableState.filterOccupation = '';
                tableState.search = '';
            }
        };
        const removeRow = (rowID) => {
            if (tableState?.rows) {
                tableState.rows = tableState.rows.filter(row => row.id !== rowID);
                showSnackBarRemoval.value = true;
            }
        };
        const handleClick = (event, row) => {
            const rawRow = toRaw(row); // Convert the reactive proxy to its original raw object
            window.open(`/showcase/${rawRow.item.id}`, "_blank");
        };
        __expose({ removeRow, handleClick });
        return (_ctx, _cache) => {
            const _component_v_alert = _resolveComponent("v-alert");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_MainShowCaseDraggableStats = _resolveComponent("MainShowCaseDraggableStats");
            const _component_v_switch = _resolveComponent("v-switch");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_chip = _resolveComponent("v-chip");
            const _component_v_data_table = _resolveComponent("v-data-table");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_container = _resolveComponent("v-container");
            const _component_v_snackbar = _resolveComponent("v-snackbar");
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                            "mobile-breakpoint": "xs",
                            class: "pt-5"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_alert, {
                                            border: "start",
                                            color: "indigo-darken-3",
                                            icon: "mdi-information-variant-circle",
                                            title: "Vue 3 Demos",
                                            ariant: 'tonal',
                                            text: "This feature has been coded from scratch using Vue3, Vuetify and Pinia. You Can Edit Candidates, Delete Candidates, Contact one of them (I wonder who that might be... 👀), Sort Candidates, and search through the data-table with ease. Have fun !!!"
                                        })
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_MainShowCaseDraggableStats),
                        (_unref(tableState))
                            ? (_openBlock(), _createBlock(_component_v_card, {
                                key: 0,
                                class: "rounded-xl pt-5 mb-5"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_row, { "mobile-breakpoint": "xs" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_col, {
                                                cols: "12",
                                                md: "4"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(AppInputField, {
                                                        modelValue: _unref(tableState).search,
                                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_unref(tableState).search) = $event)),
                                                        prependIcon: _unref(tableState).search ? 'mdi-filter-check' : 'mdi-filter',
                                                        inputPlaceholder: 'Type anything...',
                                                        inputLabel: 'Filter candidates',
                                                        inputType: 'text',
                                                        inputId: 'uniq-filter-name',
                                                        inputClearable: true
                                                    }, null, 8, ["modelValue", "prependIcon"])
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                                cols: "12",
                                                md: "4"
                                            }, {
                                                default: _withCtx(() => [
                                                    (_openBlock(), _createBlock(AppInputField, {
                                                        key: _unref(tableState).filterOccupation || 'default-key',
                                                        modelValue: _unref(tableState).filterOccupation,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_unref(tableState).filterOccupation) = $event)),
                                                        prependIcon: _unref(tableState).filterOccupation ? 'mdi-briefcase-check' : 'mdi-briefcase',
                                                        selectItems: _unref(tableState).availableOccupations,
                                                        inputPlaceholder: 'Filter by occupation...',
                                                        inputLabel: 'Filter by occupation',
                                                        inputType: 'select',
                                                        inputId: 'uniq-filter-occupation',
                                                        inputClearable: true
                                                    }, null, 8, ["modelValue", "prependIcon", "selectItems"]))
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                                cols: "12",
                                                md: "2"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_switch, {
                                                        class: "pt-2",
                                                        id: "filterAvailability",
                                                        color: "indigo-accent-3",
                                                        modelValue: _unref(tableState).filterAvailability,
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_unref(tableState).filterAvailability) = $event)),
                                                        label: "Availability"
                                                    }, null, 8, ["modelValue"])
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                                cols: "12",
                                                md: "2",
                                                class: "text-right"
                                            }, {
                                                default: _withCtx(() => [
                                                    _withDirectives(_createVNode(_component_v_btn, {
                                                        onClick: _cache[3] || (_cache[3] = ($event) => (clearFilters())),
                                                        disabled: disableClearFilter.value,
                                                        class: "mt-sm-4 mr-2",
                                                        icon: "mdi-filter-remove-outline",
                                                        variant: "tonal",
                                                        color: "red-accent-3"
                                                    }, null, 8, ["disabled"]), [
                                                        [_directive_tooltip, 'Clear Filters', "top"]
                                                    ]),
                                                    _createVNode(AppModalDialog, {
                                                        btnIcon: 'mdi-account-plus-outline',
                                                        modalSlug: 'new-entry-modal',
                                                        modalTitle: 'New Candidate',
                                                        modalHeader: 'New Candidate',
                                                        btnClasses: 'mt-sm-4 mr-2',
                                                        btnTooltip: 'New Candidate',
                                                        btnVariant: 'tonal',
                                                        btnColor: 'indigo-accent-3'
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(ShowCaseNewEntryForm)
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_v_data_table, {
                                        "sort-by": _unref(tableState).sortBy,
                                        "onUpdate:sortBy": _cache[5] || (_cache[5] = ($event) => ((_unref(tableState).sortBy) = $event)),
                                        items: filteredRows.value,
                                        search: _unref(tableState).search,
                                        hover: true,
                                        "onClick:row": handleClick,
                                        density: tableLayout.value ? 'comfortable' : 'compact',
                                        headers: [
                                            { title: '', value: 'icon', sortable: false, align: 'center' },
                                            { title: 'Name', value: 'name', sortable: true },
                                            { title: 'Email', value: 'email', sortable: true },
                                            { title: 'Age', value: 'age', sortable: true, align: 'center' },
                                            { title: 'Phone', value: 'phone', sortable: true },
                                            { title: 'Address', value: 'address', sortable: true },
                                            { title: 'Occupation', value: 'occupation', sortable: true, align: 'center' },
                                            { title: 'Availability', value: 'availability', sortable: true, align: 'center' },
                                            { title: 'Actions', value: 'actions', sortable: false, align: 'center' }
                                        ]
                                    }, {
                                        "item.icon": _withCtx(({ item }) => [
                                            _withDirectives((_openBlock(), _createBlock(_component_v_icon, null, {
                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                    _createTextVNode("mdi-account")
                                                ])),
                                                _: 1
                                            })), [
                                                [_directive_tooltip, 'View details', "top"]
                                            ])
                                        ]),
                                        "item.availability": _withCtx(({ item }) => [
                                            _createVNode(_component_v_chip, {
                                                color: item.availability ? 'green' : 'red',
                                                text: item.availability ? 'Available' : 'Not Available',
                                                class: "text-uppercase",
                                                size: "small",
                                                label: ""
                                            }, null, 8, ["color", "text"])
                                        ]),
                                        "item.actions": _withCtx(({ item }) => [
                                            _createElementVNode("div", _hoisted_1, [
                                                _withDirectives(_createVNode(_component_v_btn, {
                                                    onClick: _cache[4] || (_cache[4] = ($event) => (goToLinkedIn())),
                                                    disabled: !item.availability,
                                                    size: "x-small",
                                                    class: "mr-2",
                                                    icon: "mdi-human-greeting-proximity",
                                                    color: "green-accent-3",
                                                    variant: "tonal"
                                                }, null, 8, ["disabled"]), [
                                                    [_directive_tooltip, 'Contact', "top"]
                                                ]),
                                                _createVNode(AppModalDialog, {
                                                    btnDisabled: item.availability,
                                                    btnTooltip: 'Edit Candidate',
                                                    btnVariant: 'tonal',
                                                    modalSlug: 'edit-entry-modal',
                                                    modalTitle: 'Edit Candidate',
                                                    modalHeader: 'Edit Candidate',
                                                    btnClasses: 'mr-2',
                                                    btnColor: 'indigo-accent-3',
                                                    btnIcon: 'mdi-pencil-outline',
                                                    btnSize: 'x-small'
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(ShowCaseEditEntryForm, {
                                                            candidateId: item.id
                                                        }, null, 8, ["candidateId"])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["btnDisabled"]),
                                                _createVNode(AppModalDialog, {
                                                    btnDisabled: item.availability,
                                                    btnIcon: 'mdi-delete-outline',
                                                    modalSlug: 'new-entry-modal',
                                                    modalTitle: 'Delete Candidate',
                                                    modalHeader: 'Are you Sure?',
                                                    btnTooltip: 'Delete Candidate',
                                                    btnVariant: 'tonal',
                                                    btnColor: 'red-accent-3',
                                                    btnSize: 'x-small'
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(ShowCaseConfirmDeletion, {
                                                            onConfirmDeletion: ($event) => (removeRow(item.id))
                                                        }, null, 8, ["onConfirmDeletion"])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["btnDisabled"])
                                            ])
                                        ]),
                                        _: 1
                                    }, 8, ["sort-by", "items", "search", "density"])
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }),
                _createVNode(_component_v_snackbar, {
                    modelValue: showSnackBarRemoval.value,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((showSnackBarRemoval).value = $event)),
                    timeout: "2000",
                    color: "indigo-accent-3"
                }, {
                    actions: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                            color: "red",
                            variant: "text",
                            onClick: _cache[6] || (_cache[6] = ($event) => (showSnackBarRemoval.value = false)),
                            icon: "mdi-close"
                        })
                    ]),
                    default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createTextVNode(" Candidate has been removed successfully, bye! "))
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ], 64));
        };
    }
});
