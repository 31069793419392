<template>
  <span>
    <span v-if="themeOfTheMonth === 'theme-halloween'">
      <span v-if="faces[0] ==='1'">🕸</span>
      <span v-if="faces[0] ==='2'">👻</span>
      <span v-if="faces[0] ==='3'">🧙</span>
      <span v-if="faces[0] ==='4'">🦇</span>
      <span v-if="faces[0] ==='5'">💀</span>
      <span v-if="faces[0] ==='6'">🍬</span>
    </span>
    <span v-if="themeOfTheMonth === 'theme-christmas'">
      <span v-if="faces[0] ==='1'">🎄</span>
      <span v-if="faces[0] ==='2'">🦌</span>
      <span v-if="faces[0] ==='3'">🍪</span>
      <span v-if="faces[0] ==='4'">⛄</span>
      <span v-if="faces[0] ==='5'">❄</span>
      <span v-if="faces[0] ==='6'">🧦</span>
    </span>
    <span v-if="themeOfTheMonth === 'theme-standard'">
      <span v-if="faces[0] ==='1'">ᕕ(ᐛ)ᕗ</span>
      <span v-if="faces[0] ==='2'">(☞ﾟ∀ﾟ)☞</span>
      <span v-if="faces[0] ==='3'">(~˘▾˘)~</span>
      <span v-if="faces[0] ==='4'">~(˘▾˘~)</span>
      <span v-if="faces[0] ==='5'">☜(⌒▽⌒)☞</span>
      <span v-if="faces[0] ==='6'">☜(˚▽˚)☞</span>
    </span>
  </span>
</template>
<style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style>
<script>
  export default {
    props: ["themeOfTheMonth"],
    data() {
      return {
        faces: [],
      }
    },
    mounted() {
      this.randomizeFaces();
    },
    methods: {
      randomizeFaces() {
        this.faces = ["1","2","3","4","5","6"];
        var currentIndex = this.faces.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = this.faces[currentIndex];
          this.faces[currentIndex] = this.faces[randomIndex];
          this.faces[randomIndex] = temporaryValue;
        }
      },
    }
  }
</script>
