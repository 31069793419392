// src/plugins/vuetify.js
import { createVuetify } from 'vuetify'
import 'vuetify/styles'  // Ensure you import Vuetify's CSS
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Import the default theme styles
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // You can choose any icon set you prefer
    aliases,
    sets: {
      mdi,
    },
  },
})

export default vuetify
