<template>
  <v-container>
    <v-card class="rounded-xl">
      <v-col>
        <v-row v-if="selectedCandidate">
          <v-col>
            <div class="d-flex align-center">
              <v-icon size="x-large">mdi-account-details</v-icon><h1 class="ml-2" v-if="selectedCandidate.name">{{ selectedCandidate.name }}</h1>
            </div>
            <p v-if="selectedCandidate.email"><strong>Email:</strong> {{ selectedCandidate.email }}</p>
            <p v-if="selectedCandidate.age"><strong>Age:</strong> {{ selectedCandidate.age }}</p>
            <p v-if="selectedCandidate.phone"><strong>Phone:</strong> {{ selectedCandidate.phone }}</p>
            <p v-if="selectedCandidate.address"><strong>Address:</strong> {{ selectedCandidate.address }}</p>
            <p v-if="selectedCandidate.occupation"><strong>Occupation:</strong> {{ selectedCandidate.occupation }}</p>
          </v-col>
          <v-col v-if="selectedCandidate.availability">
            <v-btn @click="goToLinkedIn()" color="green-accent-3" variant="outlined">Contact</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script setup>
  import { inject, computed } from 'vue';
  import { useRoute } from 'vue-router';
  const route = useRoute();
  const candidateId = route.params.id; // This should match the :id parameter in the route
  const tableState = inject('tableState');

  const selectedCandidate = computed(() => {
    if (tableState) {
      console.log('tableState candidatId', tableState, candidateId);
      const localCopy = { ...tableState.rows.find(candidate => candidate.id == candidateId) };
      return localCopy || {};
    } else {
      console.log('tableState', tableState);
      return {}; // Return an empty object when tableState is not available
    }
  });
  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/antonylourenco/", "_blank");
  };
</script>