<template>
  <div class="notes-wrapper">
    <div :class="notesOpen ? 'notes-card open' : 'notes-card'">
      <div @click="toggleNotes" :class="notesOpen ? 'notes-toggle icons Close' : 'notes-toggle icons Edit-1'"></div>
      <div v-if="notesOpen">
        <h2>StandUp Notes</h2>
        <div class="input-wrapper">
          <mavon-editor v-model="note" placeholder="One day I will find the right words, and they will be simple..."
            lang="en" :toolbars="toolbars" :xssOptions="xssOptions" />
          <div @click="copyNote" :class="note.length > 0 ? 'copy-button' : 'copy-button disabled'">
            copy
            <div class="icons Document-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notesOpen: false,
      note: '',
      toolbars: {
        defaultOpen: false,
        bold: true,
        italic: false,
        header: false,
        underline: false,
        strikethrough: false,
        mark: false,
        superscript: false,
        subscript: false,
        quote: false,
        ol: true,
        ul: true,
        link: false,
        imagelink: false,
        code: false,
        table: false,
        fullscreen: false,
        readmodel: false,
        htmlcode: false,
        help: false,
        undo: false,
        redo: false,
        trash: true,
        save: false,
        navigation: false,
        alignleft: false,
        aligncenter: false,
        alignright: false,
        subfield: false,
        preview: false,
      },
      xssOptions: {
        whiteList: {
          span: ['style'],
          a: ['href', 'title', 'target']
        }
      },
    }
  },
  methods: {
    toggleNotes() {
      this.notesOpen = !this.notesOpen;
    },
    copyNote() {
      if (this.note.length > 0) {
        const dummyTextArea = document.createElement('textarea');
        dummyTextArea.value = this.note;
        document.body.appendChild(dummyTextArea);
        dummyTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(dummyTextArea);
      }
    }
  },
}
</script>
