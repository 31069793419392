<template>
	<div>
		<div class="content-toggler" :class="showContent ? 'active' : ''" @click="toggleContent">
			<h3>
				<span class="icons" :class="toggler.icon"></span>
				<span class="text" v-html="$t(`${toggler.category}_category_${toggler.id}`)"></span>
			</h3>
			<div class="icons Arrow-2" :class="showContent ? 'down' : ''"></div>
			<div v-if="showContent" class="content-section tool-section">
				<toolcard v-for="(card, index, key) in toggler.cards" :key="key" :card="card"></toolcard>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["toggler", 'index'],
		data() {
			return {
				showContent: false,
			}
		},
		methods: {
			toggleContent() {
				this.showContent = !this.showContent;
			},
		},
	}
</script>
