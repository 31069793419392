<template>
  <div>
    <v-select v-if="inputType === 'select'"
      class="ma-2"
      :id="inputId"
      :label="inputLabel"
      :value="modelValue"
      :rules="computedRules"
      @update:modelValue="$emit('update:modelValue', $event)"
      @input="$emit('update:modelValue', $event)"
      @click:clear="$emit('update:modelValue', $event.target.value)"
      :clearable="inputClearable"
      :clear-icon="inputClearIcon"
      :prepend-icon="prependIcon"
      :placeholder="inputPlaceholder"
      :items="selectItems" persistent-placeholder>
    </v-select>
    <v-text-field v-else
      class="ma-2"
      :id="inputId"
      :label="inputLabel"
      :model-value="modelValue"
      :type="inputType"
      :clearable="inputClearable"
      :clear-icon="inputClearIcon"
      :rules="computedRules"
      @input="$emit('update:modelValue', $event.target.value)"
      @click:clear="$emit('update:modelValue', $event.target.value)"
      :prepend-icon="prependIcon"
      :placeholder="inputPlaceholder"
      persistent-placeholder>
    </v-text-field>
  </div>
</template>

<script setup>
  import { computed, watch, ref } from 'vue';
  const props = defineProps({
    inputId: {
      type: String,
      required: true,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputValidation: {
      type: String,
      default: '',
    },
    inputRequired: {
      type: Boolean,
      default: false,
    },
    selectItems: {
      type: Array,
      default: []
    },
    inputClearIcon: {
      type: String,
      default: 'mdi-backspace'
    },
    inputClearable: {
      type: Boolean,
      default: false
    },
  });

  // Default rules for validation
  const rules = {
    required: value => !!value || 'Field is required',
    email: value => /.+@.+\..+/.test(value) || 'E-mail must be valid.',
    number: value => /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(value) || 'Must be a valid number',
  };

  // State to track whether the input has been touched
  const touched = ref(false);

  // Watcher to detect user interaction and mark the input as touched
  watch(() => props.modelValue, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      touched.value = true; // Input has been modified, mark as touched
    }
  });

  // Computed rules based on input type
  const computedRules = computed(() => {
    let ruleSet = [];

    // Apply required rule if input is required
    if (props.inputRequired) {
      ruleSet.push(rules.required);
    }

    // Apply email rule if input is 'email' and either required or touched
    if (props.inputType === 'email' && (props.inputRequired || touched.value)) {
      ruleSet.push(rules.email);
    }

    // Apply number rule if input is 'number' and either required or touched
    if (props.inputType === 'number' && (props.inputRequired || touched.value)) {
      ruleSet.push(rules.number);
    }

    return ruleSet;
  });
</script>

<style>
   .v-input .v-field__clearable > .v-icon:before {
    color: red;
  }
  .v-select--selected .v-input__prepend .v-icon:before, .v-input--dirty .v-input__prepend .v-icon:before {
    color: rgb(61, 90, 254);
  }
</style>