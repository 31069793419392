<template>
  <li class="card" :class="['card-' + Number(cardNumber+1), showDancers? 'finish' : '', spreaded, out? 'out' : '', done? 'done' : '']" @click="toggleDone" @contextmenu="toggleOut">
    <div v-if="!showDancers" class="num" v-text="index+1"></div>
    <div v-if="!showDancers" class="name" v-text="name"></div>
    <faces v-if="showDancers" class="face" :themeOfTheMonth="themeOfTheMonth"></faces>
    <rewards v-if="!showDancers" :themeOfTheMonth="themeOfTheMonth"></rewards>
    <!-- <div :class="['avatar', name.toLowerCase()]"></div> -->
    <audio ref="done" src="sounds/snap-1.wav"></audio>
    <audio ref="out" src="sounds/snap-2.wav"></audio>
    <audio ref="spread" src="sounds/spread-1.wav"></audio>
  </li>
</template>
<script>
  export default {
    props: ["name", "index", "showDancers", "themeOfTheMonth", "mute", "team"],
    data() {
      return {
        spreaded: '',
        done: false,
        out: false,
        cardNumber: '',
			}
		},
    mounted() {
      let num = Math.floor(Math.random() * 11);
      this.cardNumber = num;
      if (this.mute) {
        this.$refs.spread.volume = 0.0;
        this.$refs.out.volume = 0.0;
        this.$refs.done.volume = 0.0;
      }
      else {
        this.$refs.spread.volume = 1.0;
        this.$refs.out.volume = 1.0;
        this.$refs.done.volume = 1.0;
      }
      setTimeout(() => { //OR =>
        this.spreaded = 'spreaded';
        this.$refs.spread.play();
      },  Number(this.index)* 150);
    },
    methods: {
      toggleDone() {
        if (this.mute) {
          this.$refs.done.volume = 0.0;
        }
        else {
          this.$refs.done.volume = 1.0;
        }
        this.done = ! this.done;
        this.out = false;
        this.$refs.done.play();
        if (this.done || this.out) {
          this.$emit('updateRemaining', this.index);
        }
      },
      toggleOut(e) {
        if (this.mute) {
          this.$refs.out.volume = 0.0;
        }
        else {
          this.$refs.out.volume = 1.0;
        }
        e.preventDefault();
        this.out = ! this.out;
        this.done = false;
        this.$refs.out.play();
        if (this.done || this.out) {
          this.$emit('updateRemaining', this.index);
        }
      },
    },
  }
</script>
