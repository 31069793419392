import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ShowCaseConfirmDeletion',
    setup(__props) {
        const closeDialog = () => {
            const modalCloser = document.querySelector('.modalCloser');
            if (modalCloser instanceof HTMLElement) {
                modalCloser.click();
            }
        };
        return (_ctx, _cache) => {
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                            color: "red",
                            text: "Cancel",
                            onClick: _cache[0] || (_cache[0] = ($event) => (closeDialog()))
                        }),
                        _createVNode(_component_v_btn, {
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('confirmDeletion'))),
                            color: "indigo-accent-3",
                            type: "submit"
                        }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("Yes, Delete.")
                            ])),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
