import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["onClick"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppModalDialog',
    props: {
        modalSlug: String,
        modalHeader: String,
        modalTitle: String,
        btnIcon: String,
        btnText: String,
        btnClasses: String,
        btnColor: String,
        btnVariant: String,
        btnSize: String,
        btnTooltip: String,
        btnDisabled: Boolean,
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_card_text = _resolveComponent("v-card-text");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_dialog = _resolveComponent("v-dialog");
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_openBlock(), _createBlock(_component_v_dialog, { "max-width": "500" }, {
                activator: _withCtx(({ props: modalSlug }) => [
                    _withDirectives(_createVNode(_component_v_btn, _mergeProps({
                        disabled: __props.btnDisabled,
                        variant: __props.btnVariant,
                        class: __props.btnClasses
                    }, modalSlug, {
                        color: __props.btnColor,
                        icon: __props.btnIcon,
                        size: __props.btnSize,
                        text: __props.btnText
                    }), null, 16, ["disabled", "variant", "class", "color", "icon", "size", "text"]), [
                        [_directive_tooltip, __props.btnTooltip, "top"]
                    ])
                ]),
                default: _withCtx(({ isActive }) => [
                    _createVNode(_component_v_card, {
                        class: "rounded-xl",
                        title: __props.modalHeader
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "default")
                                ]),
                                _: 3
                            }),
                            _createElementVNode("div", {
                                class: "modalCloser",
                                onClick: ($event) => (isActive.value = false)
                            }, null, 8, _hoisted_1)
                        ]),
                        _: 2
                    }, 1032, ["title"])
                ]),
                _: 3
            }));
        };
    }
});
