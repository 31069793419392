import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import { computed, watch, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppInputField',
    props: {
        inputId: {
            type: String,
            required: true,
        },
        prependIcon: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        inputPlaceholder: {
            type: String,
            default: '',
        },
        inputLabel: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text',
        },
        inputValidation: {
            type: String,
            default: '',
        },
        inputRequired: {
            type: Boolean,
            default: false,
        },
        selectItems: {
            type: Array,
            default: []
        },
        inputClearIcon: {
            type: String,
            default: 'mdi-backspace'
        },
        inputClearable: {
            type: Boolean,
            default: false
        },
        isNumeric: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const props = __props;
        const forceNumericInput = (event) => {
            const allowedKeys = [
                'Backspace',
                'ArrowLeft', 'ArrowRight',
                'Tab',
                'Delete',
            ];
            if (!allowedKeys.includes(event.key) && (isNaN(Number(event.key)) && props.isNumeric)) {
                event.preventDefault(); // Block non-numeric input
            }
        };
        // Default rules for validation
        const rules = {
            required: (value) => !!value || 'Field is required',
            email: (value) => /.+@.+\..+/.test(value) || 'E-mail must be valid.',
            number: (value) => /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(value) || 'Must be a valid number',
        };
        // State to track whether the input has been touched
        const touched = ref(false);
        // Watcher to detect user interaction and mark the input as touched
        watch(() => props.modelValue, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                touched.value = true; // Input has been modified, mark as touched
            }
        });
        // Computed rules based on input type
        const computedRules = computed(() => {
            let ruleSet = [];
            // Apply required rule if input is required
            if (props.inputRequired) {
                ruleSet.push(rules.required);
            }
            // Apply email rule if input is 'email' and either required or touched
            if (props.inputType === 'email' && (props.inputRequired || touched.value)) {
                ruleSet.push(rules.email);
            }
            // Apply number rule if input is 'number' and either required or touched
            if ((props.inputType === 'number' || props.isNumeric) && (props.inputRequired || touched.value)) {
                ruleSet.push(rules.number);
            }
            return ruleSet;
        });
        return (_ctx, _cache) => {
            const _component_v_select = _resolveComponent("v-select");
            const _component_v_text_field = _resolveComponent("v-text-field");
            return (_openBlock(), _createElementBlock("div", null, [
                (__props.inputType === 'select')
                    ? (_openBlock(), _createBlock(_component_v_select, {
                        key: 0,
                        class: "ma-2",
                        id: __props.inputId,
                        label: __props.inputLabel,
                        value: __props.modelValue,
                        rules: computedRules.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event))),
                        onInput: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('update:modelValue', $event))),
                        "onClick:clear": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value))),
                        clearable: __props.inputClearable,
                        "clear-icon": __props.inputClearIcon,
                        "prepend-icon": __props.prependIcon,
                        placeholder: __props.inputPlaceholder,
                        items: __props.selectItems,
                        "persistent-placeholder": ""
                    }, null, 8, ["id", "label", "value", "rules", "clearable", "clear-icon", "prepend-icon", "placeholder", "items"]))
                    : (_openBlock(), _createBlock(_component_v_text_field, {
                        key: 1,
                        class: "ma-2",
                        id: __props.inputId,
                        label: __props.inputLabel,
                        "model-value": __props.modelValue,
                        type: __props.inputType,
                        clearable: __props.inputClearable,
                        "clear-icon": __props.inputClearIcon,
                        rules: computedRules.value,
                        onInput: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value))),
                        "onClick:clear": _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value))),
                        "prepend-icon": __props.prependIcon,
                        placeholder: __props.inputPlaceholder,
                        "persistent-placeholder": "",
                        inputmode: __props.isNumeric ? 'numeric' : '',
                        onKeydown: forceNumericInput
                    }, null, 8, ["id", "label", "model-value", "type", "clearable", "clear-icon", "rules", "prepend-icon", "placeholder", "inputmode"]))
            ]));
        };
    }
});
