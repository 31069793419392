<template>
	<div>
		<div class="header-nav" :class="scrolling ? 'scrolling': ''">
			<router-link :to="{path: '/', hash: '#app'}" class="al-main-logo" aria-label="Click to go home" title="Home"></router-link>
			<div class="menu-container">
				<router-link :to="{path: '/skills',hash: '#content'}" class="link link-skills" exact>
					<div class="icons Stars"></div>
					<div class="text" v-html="$t('menu_skills')"></div>
				</router-link>
				<router-link :to="{path: '/tools',hash: '#content'}" class="link" exact>
					<div class="icons Ax"></div>
					<div class="text" v-html="$t('menu_tools')"></div>
				</router-link>
				<router-link :to="{path: '/projects',hash: '#content'}" class="link" exact>
					<div class="icons Authorization-success"></div>
					<div class="text" v-html="$t('menu_projects')"></div>
				</router-link>
				<router-link :to="{path: '/me',hash: '#content'}" class="link" exact>
					<div class="icons View-2"></div>
					<div class="text" v-html="$t('menu_me')"></div>
				</router-link>
				<label for="switch-theme" class="switch-theme">
						<input id="switch-theme" type="checkbox" aria-label="Switch Theme" v-model="theme">
						<span></span>
					</label>
				<label for="switch-language" class="switch-language" @click="changeLanguage">
						<input id="switch-language" type="checkbox" aria-label="Switch Language" v-model="language">
						<span></span>
					</label>
				<div class="link settings" @click="toggleSettings">
					<div class="icons Settings"></div>
					<div class="text" v-html="$t('menu_settings')"></div>
				</div>
			</div>
		</div>
		<div class="responsive-settings" v-if="showSettings">
			<div class="icon-container" @click="toggleSettings">
				<span v-html="$t('menu_close')"></span>
				<span class="icons Close"></span>
			</div>
			<router-link to="" href="#app" class="al-main-logo" aria-label="Click to go home" title="Home"></router-link>
			<div class="d-flex justify-content-center align-items-center">
				<label for="switch-theme-responsive" class="switch-theme">
						<input id="switch-theme-responsive" type="checkbox" aria-label="Switch Theme" v-model="theme">
						<span></span>
					</label>
				<label for="switch-language-responsive" class="switch-language" @click="changeLanguage">
						<input id="switch-language-responsive" type="checkbox" aria-label="Switch Language" v-model="language">
						<span></span>
					</label>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		inject,
		computed,
		ref
	} from 'vue'
	export default {
		setup() {
			const {
				alTheme,
				updateAlTheme
			} = inject('alTheme', {
				alTheme: ref('dark'),
				updateAlTheme: () => {}
			});
			const theme = computed({
				get() {
					return alTheme.value === 'dark';
				},
				set(value) {
					const newTheme = value ? 'dark' : 'light';
					updateAlTheme(newTheme);
				}
			});
			return {
				theme
			};
		},
		data() {
			return {
				language: false,
				scrolling: false,
				showSettings: false,
			}
		},
		mounted() {
			const storedLanguage = localStorage.getItem('al-language');
			if (storedLanguage) {
				if (storedLanguage === "en") {
					this.language = false;
				} else {
					this.language = true;
				}
			}
		},
		created() {
			document.addEventListener('scroll', this.handleScroll);
		},
		computed: {
			alLanguage() {
				return this.language ? "fr" : "en";
			}
		},
		methods: {
			changeLanguage() {
				this.language = !this.language;
				localStorage.setItem('al-language', this.alLanguage);
				this.$emit('updateLanguage', this.alLanguage);
			},
			handleScroll() {
				if (document.body.scrollTop > 140 || document.documentElement.scrollTop > 140) {
					this.scrolling = true;
				} else {
					this.scrolling = '';
				}
			},
			toggleSettings() {
				this.showSettings = !this.showSettings;
			},
		},
	}
</script>
