<template>
  <span>
    <button :ref="`btn-${index}`" @click="openModal(index)" class="btn edit">
      <span class="icons Edit"></span>
      <div>EDIT</div>
    </button>
    <dialog :ref="`modal-${index}`" :class="deletion ? 'modal delete' : 'modal'">
      <h2>
        <span class="team-deletion">
          {{team ? team : "Team Name"}}
          <span class="icons Trash" @click="deleteTeam(index)" @mouseover="updateBackdDropColor" @mouseout="updateBackdDropColor"></span>
        </span>
        <button @click="closeModal(index)" class="close-icon open">
          <span></span>
          <span></span>
          <span></span>
          <div>CLOSE</div>
        </button>
      </h2>
      <form id="teamNameForm" @submit.prevent="changeTeamName(index)">
        <label for="change-name">
          Team Name
          <div class="input-and-sumbit">
            <input type="text" name="change-name" id="change-name" placeholder="Team Name" v-model="teamName">
            <button form="teamNameForm" type='submit' v-if="teamName.length > 0" class="btn" @click="changeTeamName(index)">&#8629;</button>
            <i v-if="teamName.length > 0">Modal will close on sumbit, please re edit if you need further changes</i>
          </div>
        </label>
      </form>
      <form id="AddMemeberForm" @submit.prevent="addMember">
        <h3>Members: <i>(max. 10)</i></h3>
        <ol>
          <li v-for="(name, index, key) in names" :key="key" :index="index" :class="{ 'deleting': isDeleting[index] }">
            <span class="member">
              <span class="icons User-1"></span>
              {{name}}
            </span>
            <span @mouseover="setIsDeleting(index)" @mouseout="setIsDeleting(index)" @click="deleteMember(index)" class="icons Trash"></span>
          </li>
          <label>
            <span class="dynamic-panel info" v-if="names.length == 0">
              No members in the team yet...
            </span>
          </label>
        </ol>
        <label for="add-member">
          Add Team Member
          <div class="input-and-sumbit">
            <input type="text" name="add-member" id="add-member" placeholder="New Member Name" v-model="newName">
            <button form="AddMemeberForm" type='submit' v-if="newName.length > 0" class="btn" @click="addMember">&#8629;</button>
          </div>
        </label>
        <span class="dynamic-panel error" v-if=" maxMembers">
          <span class="icons Security-announcement"></span>
          <span>You have reached the maximum team members allowed in a single team.</span>
        </span>
      </form>
    </dialog>
  </span>
</template>
<script>
export default {
  props: ["team", "names", "index"],
  data() {
    return {
      newName: '',
      teamName: '',
      deletion: false,
      isDeleting: [],
    }
  },
  methods: {
    setIsDeleting(index) {
      this.$set(this.isDeleting, index, !this.isDeleting[index]);
    },
    openModal(index) {
      this.$refs[`modal-${index}`].showModal();
    },
    closeModal(index) {
      this.$refs[`modal-${index}`].close();
    },
    updateBackdDropColor() {
      this.deletion = !this.deletion;
    },
    addMember() {
      if (!this.maxMembers && this.newName.trim() !== '') {
        this.$emit('addTeamMember', this.team, this.newName);
        this.newName = '';
      }
    },
    changeTeamName(index) {
      if (this.teamName.trim() !== '') {
        this.$emit('updateTeamName', this.teamName, index);
        this.teamName = '';
        this.$emit('closeMenu');
      }
    },
    deleteTeam() {
      this.$emit('deleteTeam', this.index);
      this.$emit('closeMenu');
    },
    deleteMember(index) {
      this.$emit('deleteTeamMember', this.team, index);
    }
  },
  computed: {
    maxMembers() {
      return this.names.length >= 10;
    }
  },
}
</script>
