<template>
	<div>
		<div :class="[alTheme + '-theme', isScrolling ? 'user-scrolling' : '']">
			<!-- Only render the full layout if `noLayout` is not true -->
			<div v-if="!$route.meta.noLayout" class="main-container-content">
				<div class="main-container-content-image">
					<mainmenu @updateLanguage="changeLanguage"></mainmenu>
					<signature></signature>
				</div>
				<div class="content-container" id="content">
					<RouterView />
				</div>
				<mainfooter></mainfooter>
			</div>
			<!-- For routes with `noLayout` -->
			<div v-else>
				<v-app :theme="alTheme">
					<v-main>
						<RouterView />
					</v-main>
				</v-app>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useTableStore } from '@assets/js/fakeCandidates.js';
import { provide, ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
const { tableState } = useTableStore();

provide('tableState', tableState);

// Language and Theme state
const { locale } = useI18n();
const alTheme = ref('dark');
const alLanguage = ref('en');
const isScrolling = ref(false);
let scrollTimeout;

// Function to change language
const changeLanguage = (value) => {
  locale.value = value;
};

const isShowCaseDarkTheme = ref(true);

// Update theme and store it in localStorage
const updateAlTheme = (newValue) => {
  alTheme.value = newValue;
  localStorage.setItem('al-theme', newValue);
};

// Provide theme state and updater to the rest of the app
provide('alTheme', {
  alTheme,
  updateAlTheme
});

// Lifecycle hooks
onMounted(() => {
  const storedTheme = localStorage.getItem('al-theme');
  const storedLanguage = localStorage.getItem('al-language');

  if (storedTheme) {
    updateAlTheme(storedTheme);
  }
  if (storedLanguage) {
    changeLanguage(storedLanguage);
  }

  // Add scroll event listener
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  // Remove scroll event listener when component is destroyed
  window.removeEventListener('scroll', handleScroll);
});

// Handle scroll event
const handleScroll = () => {
  isScrolling.value = true;

  // Clear the previous timeout if it exists
  if (scrollTimeout) {
    clearTimeout(scrollTimeout);
  }

  // Check if user has reached skills section
  const skillsCardContainer = document.querySelector('.skills-card-container');
  const linkSkills = document.querySelector('.link-skills');

  if (linkSkills) {
    if (skillsCardContainer) {
      const rect = skillsCardContainer.getBoundingClientRect();
      const isInViewport = rect.top < window.innerHeight;

      if (isInViewport) {
        linkSkills.classList.add('router-link-active');
      } else {
        linkSkills.classList.remove('router-link-active');
      }
    } else {
      linkSkills.classList.remove('router-link-active');
    }
  }

  // Set a timeout to remove the isScrolling flag after scrolling stops
  scrollTimeout = setTimeout(() => {
    isScrolling.value = false;
  }, 200); // Adjust the delay as needed
};
</script>
