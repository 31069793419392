// resources/assets/js/fakeCandidates.js

import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useTableStore = defineStore('tableStore', () => {
  const tableState = reactive({
    rows: [
      {
        "id": 1,
        "name": "Liam Smith",
        "email": "liam.smith@example.com",
        "age": 28,
        "phone": "555-0101",
        "address": "123 Maple St, Springfield, IL",
        "occupation": "Software Engineer"
      },
      {
        "id": 2,
        "name": "Emma Johnson",
        "email": "emma.johnson@example.com",
        "age": 22,
        "phone": "555-0102",
        "address": "456 Oak St, Springfield, IL",
        "occupation": "Graphic Designer"
      },
      {
        "id": 3,
        "name": "Noah Williams",
        "email": "noah.williams@example.com",
        "age": 30,
        "phone": "555-0103",
        "address": "789 Pine St, Springfield, IL",
        "occupation": "Data Analyst"
      },
      {
        "id": 4,
        "name": "Olivia Brown",
        "email": "olivia.brown@example.com",
        "age": 26,
        "phone": "555-0104",
        "address": "135 Elm St, Springfield, IL",
        "occupation": "Marketing Specialist"
      },
      {
        "id": 5,
        "name": "Ava Jones",
        "email": "ava.jones@example.com",
        "age": 24,
        "phone": "555-0105",
        "address": "246 Cedar St, Springfield, IL",
        "occupation": "Project Manager"
      },
      {
        "id": 6,
        "name": "William Garcia",
        "email": "william.garcia@example.com",
        "age": 31,
        "phone": "555-0106",
        "address": "357 Birch St, Springfield, IL",
        "occupation": "Product Designer"
      },
      {
        "id": 7,
        "name": "Sophia Martinez",
        "email": "sophia.martinez@example.com",
        "age": 29,
        "phone": "555-0107",
        "address": "468 Walnut St, Springfield, IL",
        "occupation": "UX Designer"
      },
      {
        "id": 8,
        "name": "James Rodriguez",
        "email": "james.rodriguez@example.com",
        "age": 32,
        "phone": "555-0108",
        "address": "579 Cherry St, Springfield, IL",
        "occupation": "Financial Analyst"
      },
      {
        "id": 9,
        "name": "Isabella Hernandez",
        "email": "isabella.hernandez@example.com",
        "age": 27,
        "phone": "555-0109",
        "address": "680 Ash St, Springfield, IL",
        "occupation": "Web Developer"
      },
      {
        "id": 10,
        "name": "Benjamin Lee",
        "email": "benjamin.lee@example.com",
        "age": 33,
        "phone": "555-0110",
        "address": "791 Maple St, Springfield, IL",
        "occupation": "Software Tester"
      },
      {
        "id": 11,
        "name": "Mia Gonzalez",
        "email": "mia.gonzalez@example.com",
        "age": 23,
        "phone": "555-0111",
        "address": "802 Oak St, Springfield, IL",
        "occupation": "Content Writer"
      },
      {
        "id": 12,
        "name": "Lucas Perez",
        "email": "lucas.perez@example.com",
        "age": 30,
        "phone": "555-0112",
        "address": "913 Pine St, Springfield, IL",
        "occupation": "System Administrator"
      },
      {
        "id": 13,
        "name": "Charlotte Wilson",
        "email": "charlotte.wilson@example.com",
        "age": 25,
        "phone": "555-0113",
        "address": "024 Elm St, Springfield, IL",
        "occupation": "SEO Specialist"
      },
      {
        "id": 14,
        "name": "Henry Anderson",
        "email": "henry.anderson@example.com",
        "age": 34,
        "phone": "555-0114",
        "address": "135 Birch St, Springfield, IL",
        "occupation": "Network Engineer"
      },
      {
        "id": 15,
        "name": "Amelia Thomas",
        "email": "amelia.thomas@example.com",
        "age": 28,
        "phone": "555-0115",
        "address": "246 Walnut St, Springfield, IL",
        "occupation": "HR Manager"
      },
      {
        "id": 16,
        "name": "Alexander Taylor",
        "email": "alexander.taylor@example.com",
        "age": 30,
        "phone": "555-0116",
        "address": "357 Cherry St, Springfield, IL",
        "occupation": "Sales Representative"
      },
      {
        "id": 17,
        "name": "Harper Moore",
        "email": "harper.moore@example.com",
        "age": 26,
        "phone": "555-0117",
        "address": "468 Maple St, Springfield, IL",
        "occupation": "Business Analyst"
      },
      {
        "id": 18,
        "name": "Michael Jackson",
        "email": "michael.jackson@example.com",
        "age": 31,
        "phone": "555-0118",
        "address": "579 Oak St, Springfield, IL",
        "occupation": "Operations Manager"
      },
      {
        "id": 19,
        "name": "Evelyn White",
        "email": "evelyn.white@example.com",
        "age": 27,
        "phone": "555-0119",
        "address": "680 Pine St, Springfield, IL",
        "occupation": "Digital Marketer"
      },
      {
        "id": 20,
        "name": "Daniel Harris",
        "email": "daniel.harris@example.com",
        "age": 29,
        "phone": "555-0120",
        "address": "791 Elm St, Springfield, IL",
        "occupation": "Research Scientist"
      },
      {
        "id": 21,
        "name": "Aiden Martin",
        "email": "aiden.martin@example.com",
        "age": 24,
        "phone": "555-0121",
        "address": "802 Birch St, Springfield, IL",
        "occupation": "Data Scientist"
      },
      {
        "id": 22,
        "name": "Grace Thompson",
        "email": "grace.thompson@example.com",
        "age": 22,
        "phone": "555-0122",
        "address": "913 Walnut St, Springfield, IL",
        "occupation": "Customer Service Representative"
      },
      {
        "id": 23,
        "name": "Matthew Garcia",
        "email": "matthew.garcia@example.com",
        "age": 35,
        "phone": "555-0123",
        "address": "024 Cherry St, Springfield, IL",
        "occupation": "Accountant"
      },
      {
        "id": 24,
        "name": "Sofia Martinez",
        "email": "sofia.martinez@example.com",
        "age": 23,
        "phone": "555-0124",
        "address": "135 Maple St, Springfield, IL",
        "occupation": "Product Manager"
      },
      {
        "id": 25,
        "name": "Ethan Robinson",
        "email": "ethan.robinson@example.com",
        "age": 30,
        "phone": "555-0125",
        "address": "246 Oak St, Springfield, IL",
        "occupation": "Web Developer"
      },
      {
        "id": 26,
        "name": "Zoe Clark",
        "email": "zoe.clark@example.com",
        "age": 29,
        "phone": "555-0126",
        "address": "357 Pine St, Springfield, IL",
        "occupation": "Social Media Manager"
      },
      {
        "id": 27,
        "name": "Chloe Rodriguez",
        "email": "chloe.rodriguez@example.com",
        "age": 26,
        "phone": "555-0127",
        "address": "468 Elm St, Springfield, IL",
        "occupation": "Content Strategist"
      },
      {
        "id": 28,
        "name": "David Lewis",
        "email": "david.lewis@example.com",
        "age": 33,
        "phone": "555-0128",
        "address": "579 Birch St, Springfield, IL",
        "occupation": "Software Architect"
      },
      {
        "id": 29,
        "name": "Victoria Lee",
        "email": "victoria.lee@example.com",
        "age": 31,
        "phone": "555-0129",
        "address": "680 Walnut St, Springfield, IL",
        "occupation": "Financial Consultant"
      },
      {
        "id": 30,
        "name": "Sebastian Walker",
        "email": "sebastian.walker@example.com",
        "age": 28,
        "phone": "555-0130",
        "address": "791 Cherry St, Springfield, IL",
        "occupation": "Machine Learning Engineer"
      },
      {
        "id": 31,
        "name": "Luna Hall",
        "email": "luna.hall@example.com",
        "age": 25,
        "phone": "555-0131",
        "address": "802 Maple St, Springfield, IL",
        "occupation": "Graphic Artist"
      },
      {
        "id": 32,
        "name": "Matthew Allen",
        "email": "matthew.allen@example.com",
        "age": 30,
        "phone": "555-0132",
        "address": "913 Oak St, Springfield, IL",
        "occupation": "IT Support Specialist"
      },
      {
        "id": 33,
        "name": "Ella Young",
        "email": "ella.young@example.com",
        "age": 24,
        "phone": "555-0133",
        "address": "024 Pine St, Springfield, IL",
        "occupation": "Sales Manager"
      },
      {
        "id": 34,
        "name": "Jackson King",
        "email": "jackson.king@example.com",
        "age": 34,
        "phone": "555-0134",
        "address": "135 Elm St, Springfield, IL",
        "occupation": "Web Analyst"
      },
      {
        "id": 35,
        "name": "Scarlett Wright",
        "email": "scarlett.wright@example.com",
        "age": 29,
        "phone": "555-0135",
        "address": "246 Birch St, Springfield, IL",
        "occupation": "E-commerce Specialist"
      },
      {
        "id": 36,
        "name": "Samuel Scott",
        "email": "samuel.scott@example.com",
        "age": 32,
        "phone": "555-0136",
        "address": "357 Walnut St, Springfield, IL",
        "occupation": "Business Consultant"
      },
      {
        "id": 37,
        "name": "Aria Torres",
        "email": "aria.torres@example.com",
        "age": 27,
        "phone": "555-0137",
        "address": "468 Cherry St, Springfield, IL",
        "occupation": "Quality Assurance Engineer"
      },
      {
        "id": 38,
        "name": "Isaac Nguyen",
        "email": "isaac.nguyen@example.com",
        "age": 31,
        "phone": "555-0138",
        "address": "579 Maple St, Springfield, IL",
        "occupation": "DevOps Engineer"
      },
      {
        "id": 39,
        "name": "Nora Hill",
        "email": "nora.hill@example.com",
        "age": 26,
        "phone": "555-0139",
        "address": "680 Oak St, Springfield, IL",
        "occupation": "Technical Writer"
      },
      {
        "id": 40,
        "name": "Owen Flores",
        "email": "owen.flores@example.com",
        "age": 28,
        "phone": "555-0140",
        "address": "791 Pine St, Springfield, IL",
        "occupation": "Cybersecurity Analyst"
      },
      {
        "id": 41,
        "name": "Samantha Lee",
        "email": "samantha.lee@example.com",
        "age": 25,
        "phone": "555-0141",
        "address": "802 Birch St, Springfield, IL",
        "occupation": "Public Relations Specialist"
      },
      {
        "id": 42,
        "name": "Henry Davis",
        "email": "henry.davis@example.com",
        "age": 36,
        "phone": "555-0142",
        "address": "913 Walnut St, Springfield, IL",
        "occupation": "Operations Director"
      },
      {
        "id": 43,
        "name": "Mia White",
        "email": "mia.white@example.com",
        "age": 22,
        "phone": "555-0143",
        "address": "024 Maple St, Springfield, IL",
        "occupation": "Research Assistant"
      },
      {
        "id": 44,
        "name": "Jacob Turner",
        "email": "jacob.turner@example.com",
        "age": 29,
        "phone": "555-0144",
        "address": "135 Oak St, Springfield, IL",
        "occupation": "Supply Chain Manager"
      },
      {
        "id": 45,
        "name": "Grace Hall",
        "email": "grace.hall@example.com",
        "age": 27,
        "phone": "555-0145",
        "address": "246 Pine St, Springfield, IL",
        "occupation": "Data Entry Clerk"
      },
      {
        "id": 46,
        "name": "Logan Robinson",
        "email": "logan.robinson@example.com",
        "age": 34,
        "phone": "555-0146",
        "address": "357 Cherry St, Springfield, IL",
        "occupation": "Event Planner"
      },
      {
        "id": 47,
        "name": "Lily Cooper",
        "email": "lily.cooper@example.com",
        "age": 30,
        "phone": "555-0147",
        "address": "468 Maple St, Springfield, IL",
        "occupation": "Real Estate Agent"
      },
      {
        "id": 48,
        "name": "Ryan Lee",
        "email": "ryan.lee@example.com",
        "age": 26,
        "phone": "555-0148",
        "address": "579 Oak St, Springfield, IL",
        "occupation": "Business Development Manager"
      },
      {
        "id": 49,
        "name": "Sophie Perez",
        "email": "sophie.perez@example.com",
        "age": 29,
        "phone": "555-0149",
        "address": "680 Pine St, Springfield, IL",
        "occupation": "Software Developer"
      },
      {
        "id": 50,
        "name": "Antony Lourenco",
        "email": "antony.lourenco@example.com",
        "age": 34,
        "phone": "...",
        "address": "Planet Hearth",
        "occupation": "Frontend dev",
        "availability": true,
      }
    ],
    selectedRow: null,
    // Example of a filter or search term
    search: '',
    filterAvailability: false,
    sortBy: [{ key: 'name', order: 'asc' }],
    currentPage: 1,
    rowsPerPage: 5,
    filterOccupation: '',
    availableOccupations: [
      'Accountant',
      'Business Analyst',
      'Business Consultant',
      'Business Development Manager',
      'Content Strategist',
      'Content Writer',
      'Customer Service Representative',
      'Cybersecurity Analyst',
      'Data Analyst',
      'Data Entry Clerk',
      'Data Scientist',
      'DevOps Engineer',
      'Digital Marketer',
      'E-commerce Specialist',
      'Event Planner',
      'Financial Analyst',
      'Financial Consultant',
      'Frontend dev',
      'Graphic Artist',
      'Graphic Designer',
      'HR Manager',
      'IT Support Specialist',
      'Machine Learning Engineer',
      'Marketing Specialist',
      'Network Engineer',
      'Operations Director',
      'Operations Manager',
      'Product Designer',
      'Product Manager',
      'Project Manager',
      'Public Relations Specialist',
      'Quality Assurance Engineer',
      'Real Estate Agent',
      'Research Assistant',
      'Research Scientist',
      'Sales Manager',
      'Sales Representative',
      'SEO Specialist',
      'Social Media Manager',
      'Software Architect',
      'Software Developer',
      'Software Engineer',
      'Software Tester',
      'Supply Chain Manager',
      'System Administrator',
      'Technical Writer',
      'UX Designer',
      'Web Analyst',
      'Web Developer',
      'Other'
    ]
  });

  return { tableState };

  }, {
  persist: true // Enabling persistence here
});