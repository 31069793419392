<template>
  <v-container>
    <v-row mobile-breakpoint="xs" class="pt-5">
      <v-col  cols="12">
        <v-alert
          border="start"
          color="indigo-darken-3"
          icon="mdi-information-variant-circle"
          title="Vue 3 Demos"
          :ariant="'tonal'"
          text="This feature has been coded from scratch using Vue3, Vuetify and Pinia. You Can Edit Candidates, Delete Candidates, Contact one of them (I wonder who that might be... 👀), Sort Candidates, and search through the data-table with ease. Have fun !!!">
        </v-alert>
      </v-col>
    </v-row>
    <MainShowCaseDraggableStats/>
    <v-card class="rounded-xl pt-5 mb-5">
      <v-row mobile-breakpoint="xs">
        <v-col cols="12" md="4">
          <AppInputField
            v-model:modelValue="tableState.search"
            :prependIcon="tableState.search ? 'mdi-filter-check' : 'mdi-filter'"
            :inputPlaceholder="'Type anything...'"
            :inputLabel="'Filter candidates'"
            :inputType="'text'"
            :inputId="'uniq-filter-name'"
            :inputClearable="true"
          />
        </v-col>
        <v-col cols="12" md="4">
          <AppInputField
            :key="tableState.filterOccupation || 'default-key'"
            v-model:modelValue="tableState.filterOccupation"
            :prependIcon="tableState.filterOccupation ? 'mdi-briefcase-check' : 'mdi-briefcase'"
            :selectItems="tableState.availableOccupations"
            :inputPlaceholder="'Filter by occupation...'"
            :inputLabel="'Filter by occupation'"
            :inputType="'select'"
            :inputId="'uniq-filter-occupation'"
            :inputClearable="true"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-switch
            class="pt-2"
            id="filterAvailability"
            color="indigo-accent-3"
            v-model="tableState.filterAvailability"
            label="Availability">
          </v-switch>
        </v-col>
        <v-col cols="12" md="2" class="text-right">
          <v-btn
            v-tooltip:top="'Clear Filters'"
            @click="clearFilters()"
            :disabled="disableClearFilter"
            class="mt-sm-4 mr-2"
            icon="mdi-filter-remove-outline"
            variant="tonal"
            color="red-accent-3">
          </v-btn>
          <AppModalDialog
            :btnIcon="'mdi-account-plus-outline'"
            :modalSlug="'new-entry-modal'"
            :modalTitle="'New Candidate'"
            :modalHeader="'New Candidate'"
            :btnClasses="'mt-sm-4 mr-2'"
            :btnTooltip="'New Candidate'"
            :btnVariant="'tonal'"
            :btnColor="'indigo-accent-3'">
            <ShowCaseNewEntryForm />
          </AppModalDialog>
        </v-col>
      </v-row>
      <!-- Table -->
      <v-data-table
        v-model:sort-by="tableState.sortBy"
        :items="filteredRows"
        :search="tableState.search"
        :hover="true"
        @click:row="handleClick"
        :density="tableLayout? 'comfortable' : 'compact'"
        :headers="[
          {title: '', value: 'icon', sortable: false, align: 'center'},
          {title: 'Name', value: 'name', sortable: true},
          {title: 'Email', value: 'email', sortable: true},
          {title: 'Age', value: 'age', sortable: true, align: 'center'},
          {title: 'Phone', value: 'phone', sortable: true},
          {title: 'Address', value: 'address', sortable: true},
          {title: 'Occupation', value: 'occupation', sortable: true, align: 'center'},
          {title: 'Availability', value: 'availability', sortable: true, align: 'center'},
          {title: 'Actions', value: 'actions', sortable: false, align: 'center'}
        ]">
        <template v-slot:item.icon="{ item }">
          <v-icon v-tooltip:top="'View details'">mdi-account</v-icon>
        </template>
        <template v-slot:item.availability="{ item }">
          <v-chip
            :color="item.availability ? 'green' : 'red'"
            :text="item.availability ? 'Available' : 'Not Available'"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex space-between">
            <v-btn
              @click="goToLinkedIn()"
              :disabled="!item.availability"
              v-tooltip:top="'Contact'"
              size="x-small"
              class="mr-2"
              icon="mdi-human-greeting-proximity"
              color="green-accent-3"
              variant="tonal">
            </v-btn>
            <AppModalDialog
              :btnDisabled="item.availability"
              :btnTooltip="'Edit Candidate'"
              :btnVariant="'tonal'"
              :modalSlug="'edit-entry-modal'"
              :modalTitle="'Edit Candidate'"
              :modalHeader="'Edit Candidate'"
              :btnClasses="'mr-2'"
              :btnColor="'indigo-accent-3'"
              :btnIcon="'mdi-pencil-outline'"
              :btnSize="'x-small'">
              <ShowCaseEditEntryForm :candidateId="item.id" />
            </AppModalDialog>
            <AppModalDialog
              :btnDisabled="item.availability"
              :btnIcon="'mdi-delete-outline'"
              :modalSlug="'new-entry-modal'"
              :modalTitle="'Delete Candidate'"
              :modalHeader="'Are you Sure?'"
              :btnTooltip="'Delete Candidate'"
              :btnVariant="'tonal'"
              :btnColor="'red-accent-3'"
              :btnSize="'x-small'">
              <ShowCaseConfirmDeletion @confirmDeletion="removeRow(item.id)" />
            </AppModalDialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  <v-snackbar v-model="showSnackBarRemoval" timeout="2000" color="indigo-accent-3">
    Candidate has been removed successfully, bye!
    <template v-slot:actions>
      <v-btn
        color="red"
        variant="text"
        @click="showSnackBarRemoval = false"
        icon="mdi-close"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
  import AppInputField from '@assets/js/components/FormInputs/AppInputField.vue';
  import AppModalDialog from '@assets/js/components/ui-elements/AppModalDialog.vue';
  import ShowCaseNewEntryForm from '../showcase/ShowCaseNewEntryForm.vue';
  import ShowCaseEditEntryForm from '../showcase/ShowCaseEditEntryForm.vue';
  import ShowCaseConfirmDeletion from '../showcase/ShowCaseConfirmDeletion.vue';
  import { computed, inject, ref, toRaw} from 'vue';

  const showSnackBarRemoval = ref(false);
  const tableLayout = ref(false);

  const tableState = inject('tableState');

  // Ensure tableState has default values for filters
  tableState.filterAvailability = tableState.filterAvailability || null;

  // Create a computed property for filtered rows
  const filteredRows = computed(() => {
    // Apply filters to the rows array
    return tableState.rows.filter(row => {
      // If filterAvailability is true, only show rows with availability true
      if (tableState.filterAvailability === true) {
        return row.availability === true;
      }
      if (tableState.filterOccupation) {
        return row.occupation === tableState.filterOccupation;
      }
      // If filterAvailability is false, return all rows
      return true;
    });
  });

  const disableClearFilter = computed(() => {
    return !(tableState.search || tableState.filterAvailability || tableState.filterOccupation);
  });

  const removeRow = (rowID) => {
    tableState.rows = tableState.rows.filter(row => row.id !== rowID);
    showSnackBarRemoval.value = true;
  };

  const handleClick = (event, row) => {
    const rawRow = toRaw(row); // Convert the reactive proxy to its original raw object
    window.open(`/showcase/${rawRow.item.id}`, "_blank");
  };

  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/antonylourenco/", "_blank");
  };

  const clearFilters = () => {
    tableState.filterAvailability = '';
    tableState.filterOccupation = '';
    tableState.search = '';
  };
</script>
<style>
  .v-data-table__tr:hover .mdi-account {
    color: rgb(61, 90, 254);
  }
  .stats-cards .mdi-drag {
    transition: 0.3s;
    opacity: 0.1;
  }
  .stats-cards:hover {
    cursor: grab;
  }
  .stats-cards:hover .mdi-drag {
    color: rgb(61, 90, 254);
    opacity: 1;
  }
</style>
