<template>
  <VueDraggableNext v-model="draggableCards" class="v-row pb-5">
    <v-col cols="4" v-for="(card, index) in draggableCards" :key="card.id">
      <v-card class="rounded-xl text-center d-flex align-center stats-cards">
        <v-col cols="2">
          <v-icon size="x-large">mdi-drag</v-icon>
        </v-col>
        <v-col cols="8">
          <h3 class="mb-4">{{ card.name }}</h3>
            <v-card
              v-if="card.id === 1"
              class="pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3"
              variant="tonal">
              {{ totalCandidates }}
            </v-card>
            <v-card
              v-else-if="card.id === 2"
              class="pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3"
              variant="tonal">
              {{ totalAvailableCandidates.length }}
            </v-card>
            <v-card
              v-else-if="card.id === 3"
              class="pa-4 rounded-xl text-h3 font-weight-black text-indigo-accent-3"
              variant="tonal">
              {{ averageCandidateAge }}
            </v-card>
        </v-col>
        <v-col cols="2"></v-col>
      </v-card>
    </v-col>
  </VueDraggableNext>
</template>
<script setup>
  import { VueDraggableNext } from 'vue-draggable-next';
  import { computed, inject, ref} from 'vue';

  const tableState = inject('tableState');

  const draggableCards = ref([
    { id: 1, name: 'Total Candidates' },
    { id: 2, name: 'Available Candidates' },
    { id: 3, name: 'Average Candidate\'s Age' },
  ]);

  const totalCandidates = computed(() => {
    if (tableState) {
      return tableState.rows.length;
    }
  });

  const averageCandidateAge = computed(() => {
    if (tableState && tableState.rows && tableState.rows.length > 0) {
        const totalAge = tableState.rows.reduce((sum, row) => sum + (row.age || 0), 0);
        const averageAge = totalAge / tableState.rows.length;
        return Math.round(averageAge);
    } else {
        return 0; // return 0 or another default value if there are no rows
    }
  });

  const totalAvailableCandidates = computed(() => {
    if (tableState) {
      return tableState.rows.filter(row => {
        return row.availability === true;
      });
    }
  });

</script>
