<template>
  <v-form ref="form" @submit.prevent="submitForm">
    <v-alert
      border="start"
      color="indigo-accent-3"
      icon="mdi-information-variant-circle"
      title="Watchout!"
      :ariant="'outlined'"
      text="From Validation is implemented here, don't hesitate in typing wrong data. 👀">
    </v-alert>
    <AppInputField
      class="pt-5"
      v-model:modelValue="newEntry.name"
      :inputLabel="'Candidate\'s name...'"
      :inputPlaceholder="'Candidate\'s name...'"
      :inputType="'text'"
      :inputId="'uniq-new-candidate-name'"
      :inputRequired="true"
    />
    <AppInputField
      v-model:modelValue="newEntry.email"
      :inputLabel="'Candidate\'s email...'"
      :inputPlaceholder="'Candidate\'s email...'"
      :inputType="'email'"
      :inputId="'uniq-new-candidate-email'"
      :inputRequired="false"
    />
    <AppInputField
      v-model:modelValue="newEntry.age"
      :inputLabel="'Candidate\'s age...'"
      :inputPlaceholder="'Candidate\'s age...'"
      :inputType="'number'"
      :inputId="'uniq-new-candidate-age'"
      :inputRequired="false"
    />
    <AppInputField
      v-model:modelValue="newEntry.phone"
      :inputLabel="'Candidate\'s phone...'"
      :inputPlaceholder="'Candidate\'s phone...'"
      :inputType="'text'"
      :inputId="'uniq-new-candidate-phone'"
      :inputRequired="false"
    />
    <AppInputField
      v-model:modelValue="newEntry.address"
      :inputLabel="'Candidate\'s address...'"
      :inputPlaceholder="'Candidate\'s address...'"
      :inputType="'text'"
      :inputId="'uniq-new-candidate-address'"
      :inputRequired="false"
    />
    <AppInputField
      v-model:modelValue="newEntry.occupation"
      :inputLabel="'Candidate\'s occupation...'"
      :inputPlaceholder="'Candidate\'s occupation...'"
      :inputType="'select'"
      :inputId="'uniq-new-candidate-occupation'"
      :inputRequired="false"
      :selectItems="tableState.availableOccupations"
    />
    <AppInputField v-if="newEntry.occupation === 'Other'"
      v-model:modelValue="customOccupation"
      :inputPlaceholder="'Candidate\'s custom occupation...'"
      :inputLabel="'Candidate\'s custom occupation'"
      :inputType="'text'"
      :inputRequired="newEntry.occupation === 'Other'"
      :inputId="'uniq-new-candidate-custom-occupation'"
    />
    <v-alert v-if="validationError"
      border="start"
      text="Please check the highlighted fields and try again."
      title="Oops! There seems to be an issue"
      color="error"
      icon="mdi-alert-circle"
      class="mb-5">
    </v-alert>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        text="Cancel"
        @click="closeDialog()">
      </v-btn>
      <v-btn color="indigo-accent-3" type="submit">Done</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup>
  import AppInputField from '@assets/js/components/FormInputs/AppInputField.vue';
  import { reactive, ref } from 'vue';
  import { useTableStore } from '@assets/js/fakeCandidates.js';
  const { tableState } = useTableStore();
  const uniqId = Date.now(); // Generate unique ID based on the current timestamp
  const newEntry = reactive( {
    id: uniqId,
    name: "",
    email:"",
    age: "",
    phone: "",
    address: "",
    occupation: ""
  });

  const validationError = ref(false);
  const customOccupation = ref('');

  // Create a ref for the form
  const form = ref(null);

  const submitForm = () => {
    if (form.value && form.value.isValid) {
      if (customOccupation.value) {
        newEntry.occupation = customOccupation.value;
      }
      tableState.rows.push(newEntry); // Push new entry to the array
      closeDialog();
    }
    else {
      validationError.value = true; // Handle validation error
    }
  };

  const closeDialog = () => {
    document.querySelector('.modalCloser')?.click();
  };
</script>