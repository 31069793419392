<template>
<div :class="selectedTeam ? 'main-container suspicious' : 'main-container'">
  <div class="panel">
    <div v-if="matchingPassword">
      <h2>Select a Team</h2>
      <button v-for="team in teams" :key="team.name" @click="setSelectedTeam(team)" class="btn btn-team">{{ team.name }}</button>
    </div>
    <form @submit.prevent="toggleLoggedIn" v-if="!selectedTeam && !matchingPassword">
      <div>
        <div class="titles">
            <h1>STANDUP</h1>
          </div>
        <h2>Boring Security Check</h2>
        <label for="team-password">
          <span class="icons Locked"></span><input ref="password" :type="showPassword ? 'text' : 'password'" name="team-password" id="team-password" placeholder="Enter password" v-model="password"><span @click="togglePasswordVisibility" :class="showPassword ? 'icons Security-view visible': 'icons Security-view'"></span>
          <span v-if="password.length > 0" class="enter">&#8629;</span>
        </label>
        <div>
          <small>Just count to 5...</small>
        </div>
        <button type="submit" @click="toggleLoggedIn" class="btn">Let me in!</button>
        <div v-if="errors && !matchingPassword" class="error"><span class="icons Security-close"></span>You ain't one of us!<span class="icons Security-close"></span></div>
      </div>
    </form>
  </div>
</div>
</template>
<script>
export default {
  props: ['teams'],
  data() {
    return {
      selectedTeam: null,
      password: '',
      submitted: '',
      errors: '',
      showPassword: false,
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    setSelectedTeam(team) {
      this.selectedTeam = team.name;
      if (this.matchingPassword) {
        this.$emit('matchingPassword', this.selectedTeam);
      }
    },
    toggleLoggedIn() {
      this.submitted = true;
      this.errors = true;
      setTimeout(() => {
        this.errors = false;
      }, 3000);
    },
  },
  computed: {
    matchingPassword() {
     return this.password === "12345";
    },
  }
}
</script>

