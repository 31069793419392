<template>
  <div :class="[themeOfTheMonth]" style="height: 100%;">
    <canvas id="canvas"></canvas>
    <audio ref="start" src="sounds/start-1.wav"></audio>
    <audio ref="stack" src="sounds/stack-1.wav"></audio>
    <audio ref="finish" src="sounds/finish-1.wav"></audio>
    <audio ref="crowd" src="sounds/crowd-1.wav"></audio>
    <audio ref="done" src="sounds/snap-1.wav"></audio>
    <audio ref="out" src="sounds/snap-2.wav"></audio>
    <div class="standup">
      <div v-if="!loggedIn" class="login-prompt">
        <login :teams="teams" @matchingPassword="toggleLoggedIn"></login>
      </div>
      <div class="main-container" v-if="loggedIn">
        <header class="header-nav">
          <button class="btn stack" v-if="spreaded" @click="stackCards"><span class="icons Reload"></span></button>
          <button class="btn mute" @click="toggleMute" :class="mute? 'off' : ''">
            <span class="icons Karaoke"></span>
            <div v-if="!mute">SOUND</div>
            <div v-if="mute" class="strikethrough">SOUND</div>
          </button>
          <button class="close-icon" :class="menu? 'open' : ''" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
            <div v-if="!menu">MENU</div>
            <div v-if="menu">CLOSE</div>
          </button>
        </header>
        <nav class="main-menu" v-if="menu">
          <div class="main-menu-header">
            <h2>MENU</h2>
          </div>
          <div class="main-menu-content">
            <div class="team-selector">
              SELECT ACTIVE TEAM
              <div v-for="(team, index) in teams" :key="team.name" :index="index">
                <button
                  class="btn btn-team"
                  :class="team.name === selectedTeam ? 'active' : ''"
                  @click="setSelectedTeam(team.name)"
                >
                  <span class="icons Folder"></span>
                  <span>{{ team.name.toUpperCase() }}</span>
                </button>
                <teamdialog
                  :names="team.members"
                  :team="team.name"
                  :index="index"
                  @deleteTeam="deleteTeam"
                  @addTeamMember="addTeamMember"
                  @deleteTeamMember="deleteTeamMember"
                  @updateTeamName="updateTeamName"
                  @closeMenu="toggleMenu"
                ></teamdialog>
              </div>
              <button class="btn new-team" @click="createNewTeam"><span class="icons Add-folder"></span>New team</button>
            </div>
            <hr>
            <div class="ui-help-container">
              INSTRUCTIONS
              <p><kbd>left click</kbd>to end turn.</p>
              <p><kbd>right click</kbd>to skip turn.</p>
              <p>Hey don't just stand there, Stand<strong> UP!</strong></p>
            </div>
          </div>
          <button @click="logOut" class="btn logout">
            <span class="icons Security-close"></span>
            <div>LOG OUT</div>
          </button>
        </nav>
        <div class="scrollable-container">
          <div class="titles" v-if="!showCards">
            <p>Team</p>
            <h3 v-text="selectedTeam"></h3>
            <p>Shuffle</p>
            <h1>StandUp</h1>
          </div>
          <div class="text-center btn-container">
            <button class="btn spread" @click="spreadCards()" v-if="!showCards && selectedTeam != 'No Team Selected'">¯\_(ツ)_/¯</button>
            <span class="dynamic-panel info" v-if="selectedTeam === 'No Team Selected'">
              Please select a team in the top right menu.
            </span>
          </div>
          <div class="grid">
            <ul class="list" v-if="names.length > 0 && showCards">
              <name v-for="(name, index, key) in names" :key="key" :name="name" :index="index" @updateRemaining="remainingNames" :showDancers="showConfetis" :themeOfTheMonth="themeOfTheMonth" :mute="mute" :team="selectedTeam"></name>
              <notes v-if="spreaded"></notes>
            </ul>
            <span class="dynamic-panel info" v-if="showCards && names.length == 0">
              Add members to your team in the top right menu.
            </span>
          </div>
          <div v-if="showConfetis" class="next-step">Now Let's groom a little!</div>
          <footer class="credits">
            <div>Shuffle StandUp v.2.1</div>
            <div>by <a href="https://antony-lourenco.com/" target="_blank">Antony Lourenco</a></div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns'
export default {
  data() {
    return {
      teams: [
        {
          name: "Team 1",
          members: ["Member 1", "Member 2", "Member 3", "Member 4", "Member 5", "Member 6", "Member 7", "Member 8"],
        },
        {
          name: "Team 2",
          members: ["Member 1", "Member 2", "Member 3", "Member 4", "Member 5", "Member 6"],
        },
      ],
      loggedIn: false,
      error: null,
      month: format(new Date(), "LLLL"),
      today: format(new Date(), "d-M"),
      names: [],
      passed: [],
      menu: false,
      spreaded: false,
      showCards: false,
      showConfetis: false,
      mute: '',
      selectedTeam: '',
    }
  },
  mounted() {
    this.$refs.crowd.volume = 0.1;
    this.$refs.finish.volume = 0.1;
    const storedLoggedIn = localStorage.getItem('loggedIn');
    const storedTeam = localStorage.getItem('selectedTeam');
    const storedTeams = localStorage.getItem('teams');
    const storedMute = localStorage.getItem('storedMute');

    // Check if the values exist in localStorage
    if (storedLoggedIn && storedTeam) {
      this.loggedIn = JSON.parse(storedLoggedIn);
      this.selectedTeam = storedTeam;
    }
    // Checking if data exists in localStorage and parsing it
    if (storedTeams) {
      this.teams = JSON.parse(storedTeams);
      if (this.teams.length == 0 ) {
        this.selectedTeam = 'No Team Selected';
      }
    }
    // Set mute if if has been set
    storedMute ? (this.mute = storedMute) : (this.mute = false);
    if (this.mute) {
      this.$refs.start.volume = 0.0;
      this.$refs.stack.volume = 0.0;
      this.$refs.crowd.volume = 0.0;
      this.$refs.finish.volume = 0.0;
      this.$refs.done.volume = 0.0;
      this.$refs.out.volume = 0.0;
    }
    else {
      this.$refs.crowd.volume = 0.1;
      this.$refs.finish.volume = 0.1;
      this.$refs.start.volume = 1.0;
      this.$refs.stack.volume = 1.0;
      this.$refs.done.volume = 1.0;
      this.$refs.out.volume = 1.0;
    }
  },
  methods: {
    logOut() {
      localStorage.setItem('loggedIn', false);
      localStorage.setItem('selectedTeam', '');
      window.location.reload();
    },
    addTeamMember(teamName, newName) {
      const team = this.teams.find((team) => team.name === teamName);
      if (team) {
        team.members.push(newName);
        localStorage.setItem('teams', JSON.stringify(this.teams));
      }
    },
    deleteTeamMember(teamName, index) {
      const team = this.teams.find((team) => team.name === teamName);
      if (team && team.members[index]) {
        team.members.splice(index, 1);
        localStorage.setItem('teams', JSON.stringify(this.teams));
      }
    },
    updateTeamName(teamName, index) {
      this.$set(this.teams[index], 'name', teamName);
      this.selectedTeam = teamName;
      localStorage.setItem('teams', JSON.stringify(this.teams));
    },
    createNewTeam() {
      // Get the current teams from localStorage
      const storedTeams = JSON.parse(localStorage.getItem('teams')) || [];

      // Find the highest team number
      const highestTeamNumber = storedTeams.reduce((max, team) => {
          const match = team.name.match(/New Team (\d+)/);
          const number = match ? parseInt(match[1]) : 0;
          return Math.max(max, number);
      }, 0);

      // Generate a new team name with the next number
      const newTeamName = `New Team ${highestTeamNumber + 1}`;

      // Push the new team to the array
      this.teams.push({
          name: newTeamName,
          members: [],
      });

      // Update localStorage with the new teams array
      localStorage.setItem('teams', JSON.stringify(this.teams));
    },
    deleteTeam(index) {
      if (this.teams && index) {
        this.teams.splice(index, 1);
        this.selectedTeam = 'No Team Selected';
        localStorage.setItem('teams', JSON.stringify(this.teams));
      }
    },
    toggleLoggedIn(value) {
      this.loggedIn = true;
      this.selectedTeam = value;
      // Store in localStorage
      localStorage.setItem('loggedIn', true);
      localStorage.setItem('selectedTeam', value);
    },
    toggleMute() {
      this.mute = ! this.mute;
      localStorage.setItem('storedMute', this.mute);
      this.$refs.done.play();
      if (this.mute) {
        this.$refs.start.volume = 0.0;
        this.$refs.stack.volume = 0.0;
        this.$refs.crowd.volume = 0.0;
        this.$refs.finish.volume = 0.0;
        this.$refs.done.volume = 0.0;
        this.$refs.out.volume = 0.0;
      }
      else {
        this.$refs.crowd.volume = 0.1;
        this.$refs.finish.volume = 0.1;
        this.$refs.start.volume = 1.0;
        this.$refs.stack.volume = 1.0;
        this.$refs.done.volume = 1.0;
        this.$refs.out.volume = 1.0;
      }
    },
    toggleMenu() {
      this.menu = !this.menu;
      if (!this.menu) {
        this.$refs.done.play();
      }
      else {
        this.$refs.out.play();
      }
    },
    remainingNames(value) {
      if (!this.passed.includes(value)) {
        this.passed.push(value);
        if (this.passed.length === this.names.length) {
          this.showConfetis = true;
          this.$refs.finish.play();
          this.$refs.crowd.play();
          launchConfeti(this.themeOfTheMonth);
          this.passed = [];
        }
      }
    },
    setSelectedTeam(value) {
      this.selectedTeam = value;
      localStorage.setItem('selectedTeam', value);
      this.stackCards();
      this.$refs.done.play();
    },
    spreadCards() {
      this.$refs.start.play();
      const selectedTeam = this.teams.find((team) => team.name === this.selectedTeam);

      if (selectedTeam) {
        // Clone the team's members array
        this.names = [...selectedTeam.members];

        // Shuffle the names array
        var currentIndex = this.names.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = this.names[currentIndex];
          this.names[currentIndex] = this.names[randomIndex];
          this.names[randomIndex] = temporaryValue;
        }

        this.showCards = true;

        setTimeout(() => {
          this.spreaded = true;
        }, this.lastTimeOut);
      }
      if (this.menu) {
        this.toggleMenu();
      }
    },
    stackCards() {
      if (this.spreaded === true) {
        this.$refs.stack.play();
      }
      this.names = [];
      this.spreaded = false;
      this.showCards = false;
      this.showConfetis = false;
    },
  },
  computed : {
    lastTimeOut() {
      if (this.qty) {
        return (this.qty + 1) * 150
      }
      else {
        return 0;
      }
    },
    qty() {
      return this.names.length;
    },
    themeOfTheMonth() {
      return "theme-standard";
    }
  },
}
</script>
